import { FC, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import campaignData from 'campaigns.json';
import { useIsMobile, useWithDispatch } from 'hooks';
import { fbPageTrack } from 'services/Tracking';
import { useIsLoggedIn } from 'store/auth-service';
import { setupError } from 'store/error/actions';
import { login } from 'store/user/actions';

import { Footer, NavBar } from './components';

import './Page.scss';

interface PageProps {
    header?: boolean;
    footer?: boolean;
    fixedHeader?: boolean;
    className?: string;
    type?: string;
    children?: ReactNode;
}

const campaigns = [campaignData.wedding];

export const Page: FC<PageProps> = ({ header = true, fixedHeader = true, footer = true, children, className = '' }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const isLoggedIn = useIsLoggedIn();
    const isMobile = useIsMobile();
    const loginAction = useWithDispatch(login);

    // Placed here to pop the one tap login modal on rout navigation
    useGoogleOneTapLogin({
        onSuccess: ({ credential }) => {
            if (credential) {
                loginAction({ googleIdToken: credential });
            }
        },
        disabled: isLoggedIn || isMobile
    });

    useEffect(() => {
        fbPageTrack(location.pathname);
        setupError(dispatch, {});
    }, [setupError, dispatch, location]);

    return (
        <div
            className={`page ${header ? 'with-header' : ''} ${
                header && fixedHeader ? 'fixed-header' : ''
            } ${footer ? '' : 'no-footer'} ${className}`}
        >
            {header && <NavBar />}
            {children}
            {footer && <Footer campaigns={campaigns} />}
        </div>
    );
};

export default Page;
