import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Style as StyleService } from 'services';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';

import { ProceedButton, StepContainer, Title } from '../components';
import { styleAnswers } from '../consts';
import { RatedStyles, StepComponentProps, Style, StyleId } from '../types';

import { Answer, AnswerContent, AnswersContainer, StyleImage, StyleItem, StylesContainer } from './Style-styles';

export const Styles: FC<StepComponentProps> = ({
    onboardingState: { department, styles },
    proceed,
    rollback,
    navigateToStep
}) => {
    const { innerStepIndex } = useParams<{ innerStepIndex: string }>();
    const styleIndex = Number(innerStepIndex) || 0;
    const [stylesList, setStylesList] = useState<Style[]>([]);
    const [ratedStyles, setRatedStyles] = useState<RatedStyles>(new Map(styles));
    const isLastIndex = styleIndex === stylesList.length - 1;
    const isChecked = (styleId: StyleId, value: number) => ratedStyles.get(styleId) === value;

    const proceedToNextQuestion = () => {
        if (isLastIndex) {
            proceed({ styles: new Map(ratedStyles) });
        } else {
            trackStyleRate('skip');
            nextStyle();
        }
    };

    const rate = (style: StyleId, value: number) => {
        const newState = new Map(ratedStyles);

        newState.set(style, value);
        setRatedStyles(newState);
        trackStyleRate(value);
        isLastIndex ? proceed({ styles: newState }) : nextStyle();
    };

    const nextStyle = () => navigateToStep('styles', styleIndex + 1);

    const trackStyleRate = (value: number | 'skip') =>
        trackEvent({
            name: MP_EVENTS.ONBOARDING_STYLE_SELECTION,
            properties: {
                [MP_PROPS.PAGE_NAME]: stylesList[styleIndex].style,
                [MP_PROPS.STYLE_SELECTION]: value
            }
        });

    useEffect(() => {
        !department && rollback();
    }, [department, rollback]);

    useEffect(() => {
        stylesList[styleIndex] &&
            trackEvent({
                name: MP_EVENTS.ONBOARDING_STYLE_VIEWS,
                properties: {
                    [MP_PROPS.PAGE_NAME]: stylesList[styleIndex].style
                }
            });
    }, [stylesList, styleIndex]);

    useEffect(() => {
        StyleService.getMoodBoards(department).then(({ data }) => {
            setStylesList(data as unknown as Style[]);
        });
    }, [department]);

    if (!department) {
        return <></>;
    }

    return (
        <StepContainer>
            <StylesContainer>
                {stylesList.map(({ style: styleId, displayName, picture }, index) => (
                    <StyleItem key={index} $selected={index == styleIndex}>
                        <Title>
                            Do you wear <span>{displayName}</span> style
                        </Title>
                        <StyleImage src={picture} alt={displayName} />
                        <AnswersContainer>
                            {styleAnswers.map(({ value, text }) => (
                                <Answer key={value}>
                                    <AnswerContent
                                        $selected={isChecked(styleId, value)}
                                        onClick={() => rate(styleId, value)}
                                    >
                                        {text}
                                    </AnswerContent>
                                </Answer>
                            ))}
                        </AnswersContainer>
                    </StyleItem>
                ))}
            </StylesContainer>
            {styleIndex > 5 && (
                <ProceedButton onClick={proceedToNextQuestion} isSkip={true} isLastQuestion={isLastIndex} />
            )}
        </StepContainer>
    );
};
