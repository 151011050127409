import styled from 'styled-components';

export const DepartmentsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    margin-top: 50px;

    @media screen and (max-width: 576px) {
        flex-direction: column;
        gap: 14px;
    }
`;
export const DepartmentItem = styled.div<{ $selected: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 302px;
    gap: 14px;
    border: ${({ $selected }) => ($selected ? '2px solid black' : '1px solid #979797')};
    background: white;
    border-radius: 4px;
    text-transform: uppercase;
    //letter-spacing: -0.5px;
    cursor: pointer;

    @media screen and (max-width: 576px) {
        width: 200px;
        height: 210px;
        gap: 14px;
    }
`;

export const DepartmentImage = styled.img`
    width: 236px;
    height: 236px;

    @media screen and (max-width: 576px) {
        width: 150px;
        height: 150px;
    }
`;
