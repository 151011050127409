import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Tracking } from 'services';
import { LdEvent } from 'services/LaunchDarkly';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { getOnboardingUrl } from 'services/utils/url-utils';

import { Page } from 'components';

import { Header, StepsProgressBar } from './components';
import { Container } from './Onboarding-styles';
import { OnboardingState, OnboardingStep } from './types';
import { useOnboardingComplete } from './useOnboardingComplete';
import { getProgress, getStepComponent } from './utils';

const rootUrl = getOnboardingUrl();

export const Onboarding: FC = () => {
    const isInitialized = useRef(false);
    const { step = 'needs' } = useParams<{ step: OnboardingStep }>();
    const location = useLocation();
    const navigate = useNavigate();
    const ldClient = useLDClient();
    const [onboardingState, setOnboardingState] = useState<OnboardingState>({
        source: location.state?.mpSource ?? MP_VALUES.ONBOARDING_QUIZ
    });
    const complete = useOnboardingComplete();
    const progress = useMemo(() => getProgress(step, onboardingState.department), [step, onboardingState]);
    const Step = getStepComponent(step);

    const updateOnboardingState = (newState: OnboardingState) => {
        setOnboardingState({ ...onboardingState, ...newState });
    };

    const navigateToStep = (step: OnboardingStep, innerStepIndex: string | number = '') => {
        navigate(`${rootUrl}/${step}/${innerStepIndex}`);
    };

    const proceed = (newState: OnboardingState) => {
        const state = { ...onboardingState, ...newState };
        const progress = getProgress(step, state.department); // It is important to re-fetch progress here because it is affected by newState.gender

        if (!progress) {
            rollback();
            return;
        }

        setOnboardingState(state);

        if (progress.nextStep) {
            navigateToStep(progress.nextStep);
        } else {
            complete(state);
        }
    };

    const rollback = useCallback(() => {
        navigate(`${rootUrl}/${progress?.prevStep ?? ''}`, { replace: true });
    }, [navigate, progress]);

    useEffect(() => {
        !progress && rollback();
    }, [progress, rollback]);

    if (!progress) {
        return <></>;
    }

    if (!isInitialized.current) {
        isInitialized.current = true;
        Tracking.tag({ event: 'ws.beginOnboardingQuiz' });
        trackEvent({
            name: MP_EVENTS.ONBOARDING_INTRO_VIEWS,
            properties: { [MP_PROPS.SOURCE]: onboardingState.source }
        });
        ldClient?.track(LdEvent.ONBOARDING_INTRO_VIEW);
    }

    return (
        <Page header={false} footer={false} fixedHeader={false}>
            <Container>
                <Header progress={progress} />
                <StepsProgressBar progress={progress} navigateToStep={navigateToStep} />
                <Step
                    onboardingState={onboardingState}
                    updateOnboardingState={updateOnboardingState}
                    proceed={proceed}
                    rollback={rollback}
                    navigateToStep={navigateToStep}
                />
            </Container>
        </Page>
    );
};
