import './style.scss';

import { ProfilePhoto, StylingButton } from 'components';
import content from 'content.json';
import { useCart, useWithDispatch } from 'hooks';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { Dropdown, Image, Nav, Navbar } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { getPageType, getStylistSearchUrl } from 'services/utils/url-utils';
import { useIsLoggedIn } from 'store/auth-service';
import { toggleModal } from 'store/ui/actions';
import { logout } from 'store/user/actions';
import { useUser } from 'store/user/reducer';
const { logo, navbar } = content;

export const NavBar: React.FC = () => {
    const dispatch = useDispatch();
    const user = useUser();
    const ldClient = useLDClient();
    const location = useLocation();
    const [expended, setExpended] = useState(false);
    const [toggle, setToggle] = useState(false);
    const isLoggedIn = useIsLoggedIn();
    const toggleModalAction = useWithDispatch(toggleModal);
    const { formattedTotal: formattedCartTotal, itemsCount: cartItemsCount } = useCart();

    const trackHowItWorksClick = () => {
        trackEvent({
            name: MP_EVENTS.HOW_IT_WORKS_CLICK,
            properties: { [MP_PROPS.SOURCE]: getPageType(), [MP_PROPS.ELEMENT]: MP_VALUES.NAVBAR }
        });
    };

    const trackPricingClick = () => {
        trackEvent({
            name: MP_EVENTS.PRICING_CLICK,
            properties: { [MP_PROPS.SOURCE]: getPageType(), [MP_PROPS.ELEMENT]: MP_VALUES.NAVBAR }
        });
    };

    const trackStylistsClick = () => {
        trackEvent({
            name: MP_EVENTS.STYLISTS_CLICK,
            properties: { [MP_PROPS.SOURCE]: getPageType(), [MP_PROPS.ELEMENT]: MP_VALUES.NAVBAR }
        });
    };

    const logoutClick = () => {
        logout(dispatch);

        const context = {
            kind: 'user',
            anonymous: true,
            key: '00000000-0000-0000-0'
        };

        ldClient?.identify(context);
    };

    useEffect(() => {
        setToggle(false);
    }, [location]);

    const howItWorksLink = (
        <Link to="/how-it-works" onClick={trackHowItWorksClick}>
            {navbar.gettingStarted}
        </Link>
    );

    const pricingLink = () => {
        if (!user?.has_active_sessions) {
            return (
                <Link to="/pricing" onClick={trackPricingClick}>
                    {navbar.pricing}
                </Link>
            );
        }
        return null;
    };

    const stylistsLink = (label: string, className?: string) => {
        return (
            <Link className={className} to={getStylistSearchUrl()} onClick={trackStylistsClick}>
                {label}
            </Link>
        );
    };

    const logoutLink = (
        <Link to="/" onClick={logoutClick} data-test-id="logout-button">
            {navbar.logout}
        </Link>
    );

    return (
        <>
            <Navbar className="nav-bar" expand="sm" expanded={expended}>
                <Navbar.Brand className="logo">
                    <Link to="/">
                        <Image src={logo.black} className="small-logo" />
                        <Image src={logo.text_black} className="large-logo" />
                    </Link>
                </Navbar.Brand>

                {isLoggedIn ? (
                    <>
                        <Navbar.Toggle onClick={() => setExpended(!expended)}>
                            <Image src={navbar.icons.hamburger} />
                        </Navbar.Toggle>

                        {/*Left bar*/}
                        <Nav className="mobile-nav d-flex d-sm-none">
                            {stylistsLink(navbar.stylistsMobile, 'stylistButton')}
                            <span>
                                <Link to="/inbox" className={`inbox`}>
                                    <Image className="inbox" src={navbar.icons.inbox} />
                                </Link>
                                <Link to="/shopping-list">
                                    <div className="cart-icon">{cartItemsCount ? cartItemsCount : ''}</div>
                                </Link>
                            </span>
                        </Nav>

                        <Navbar.Collapse onClick={() => setExpended(false)}>
                            <Nav>
                                <span className="d-none d-sm-flex">
                                    {pricingLink()}
                                    {stylistsLink(navbar.stylists)}
                                    <Link to="/feed">{navbar.feed}</Link>
                                    <Link to="/gift-cards">{navbar.giftsCards}</Link>
                                    {howItWorksLink}
                                    <Link to="/lux">Lux Package</Link>
                                </span>

                                <span className="d-flex d-sm-none">
                                    <Link to="/profile/closet">{navbar.profile}</Link>
                                    <Link to="/favorites">{navbar.favorites}</Link>
                                    <Link to="/feed">{navbar.feed}</Link>
                                    <Link to="/gift-cards">{navbar.giftsCards}</Link>
                                    <Link to="/lux">Lux Package</Link>
                                    {howItWorksLink}
                                    <Link to="/orders">{navbar.orders}</Link>
                                    <Link to="/settings">{navbar.settings}</Link>
                                    {logoutLink}
                                </span>
                            </Nav>

                            {/*Right bar*/}
                            <Nav className="justify-content-end">
                                <Link to="/inbox" className={`d-none d-sm-flex`}>
                                    {navbar.sessions}
                                </Link>
                                <Link to="/shopping-list" className="cart-link d-none d-sm-flex">
                                    <div className="cart-icon">{cartItemsCount ? cartItemsCount : ''}</div>
                                    {!!cartItemsCount && <div className="cart-price">({formattedCartTotal})</div>}
                                </Link>
                                <Dropdown
                                    className="user-menu d-none d-sm-flex"
                                    show={toggle}
                                    onToggle={setToggle}
                                    data-test-id="user-menu"
                                >
                                    <Dropdown.Toggle>
                                        <ProfilePhoto user={user} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu alignRight={true}>
                                        <Link to="/profile/closet">{navbar.profile}</Link>
                                        <Link to="/favorites">{navbar.favorites}</Link>
                                        <Link to="/orders">{navbar.orders}</Link>
                                        <Dropdown.Divider />
                                        <Link to="/settings">{navbar.settings}</Link>
                                        {logoutLink}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </>
                ) : (
                    <>
                        <Nav className="d-none d-sm-flex">
                            {pricingLink()}
                            {howItWorksLink}
                            <Link to="/feed">{navbar.feed}</Link>
                            <Link to="/gift-cards">{navbar.giftsCards}</Link>
                            <Link to="/lux">Lux Package</Link>
                        </Nav>
                        <Nav className="justify-content-end logged-out">
                            {location.pathname === '/gift-cards' ? (
                                <Link to="/gift-cards/choose" className="gift-card">
                                    {navbar.giftcard}
                                </Link>
                            ) : (
                                <>
                                    <Link
                                        to="#"
                                        className="signin"
                                        onClick={() => toggleModalAction({ type: 'Signin' })}
                                        data-test-id="nav-login-btn"
                                    >
                                        {navbar.login}
                                    </Link>
                                    <StylingButton
                                        className="signup"
                                        trackingElement={MP_VALUES.NAVBAR}
                                        data-test-id="nav-lets-get-styling-btn"
                                    />
                                </>
                            )}
                        </Nav>
                    </>
                )}
            </Navbar>
        </>
    );
};
