import 'bootstrap/dist/css/bootstrap.min.css'; // Do not move these css imports, it'll break Bootstrap overrides (ugh...)
import './index.scss';

import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { PersistGate } from 'redux-persist/integration/react';
import { initMixpanel } from 'services/mixpanel';
import { persistor, store } from 'store';

import { Loader } from 'components';

import SentryLogging from './services/SentryLogging';
import { App } from './App';
import { Config } from './services';

const launch = async () => {
    if (Config.isProd()) {
        TagManager.initialize({ gtmId: Config.get('gtmId') });
        SentryLogging();
    }

    const LDProvider = await asyncWithLDProvider({
        clientSideID: Config.get('launchDarklyClientSideId'),
        context: {
            kind: 'user',
            anonymous: true,
            key: '00000000-0000-0000-0'
        }
    });
    const container = document.getElementById('root');
    const root = createRoot(container!);

    initMixpanel();

    root.render(
        <Provider store={store}>
            <PersistGate loading={<Loader />} persistor={persistor}>
                <GoogleOAuthProvider clientId={Config.get('googleOAuthClientId')}>
                    <LDProvider>
                        <Router>
                            <App />
                        </Router>
                    </LDProvider>
                </GoogleOAuthProvider>
            </PersistGate>
        </Provider>
    );
};

launch();
