import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIsMobile, useWithDispatch } from 'hooks';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useApiToken } from 'store/auth-service';
import { toggleZEWidget } from 'store/common/actions';
import { initializeTwilio } from 'store/services/actions';
import { login } from 'store/user/actions';
import { useUser } from 'store/user/reducer';
import { ThemeProvider } from 'styled-components';
import { theme } from 'theme';

import { klaviyoIdentify } from './services/Klaviyo';
import { identify as identifyMixpanel, MP_EXP_VARIANTS, MP_PROPS, setProfilePropsOnce } from './services/mixpanel';
import { identifySentry } from './services/SentryLogging';
import { useNewOnboardingFlag } from './store/features-service/features-hooks';
import { AppRoutes } from './AppRoutes';
import { Modal, Toast } from './components';
import { Tracking } from './services';

const getTokenFromUrl = () => new URLSearchParams(window.location.search).get('token');

export const App: React.FC = () => {
    const path = useRef<string | null>(null);
    const loggingIn = useRef(false);
    const twilioInitialized = useRef(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const location = useLocation();
    const token = useApiToken();
    const loginToken = getTokenFromUrl() || token;
    const user = useUser();
    const loginAction = useWithDispatch(login);
    const initializeTwilioAction = useWithDispatch(initializeTwilio);
    const toggleZEWidgetAction = useWithDispatch(toggleZEWidget);
    const ldClient = useLDClient()!; // Since we are initializing LD w/ asyncWithLDProvider(), ldClient is always available
    const isMobile = useIsMobile();
    const isNewOnboarding = useNewOnboardingFlag();

    useEffect(() => {
        if (!user && !loggingIn.current) {
            loggingIn.current = true;

            if (loginToken) {
                loginAction({ token: loginToken }).then(() => setIsLoaded(true));
            } else {
                setIsLoaded(true);
            }
        }
    }, [user, loginToken, loginAction, setIsLoaded]);

    useEffect(() => {
        const { pathname } = location;
        if (pathname !== path.current) {
            path.current = pathname;

            window.scrollTo(0, 0);
            Tracking.tag({
                event: 'ws.virtualPageView',
                pagePath: pathname,
                pageTitle: document.title
            });
            if (!isMobile && process.env.NODE_ENV === 'production') {
                toggleZEWidgetAction(pathname);
            }
        }
    }, [location, isMobile, toggleZEWidgetAction]);

    useEffect(() => {
        if (user) {
            const { uuid, email, first_name, last_name } = user;
            const name = `${first_name} ${last_name}`;
            const ldContext = {
                kind: 'user',
                key: uuid,
                name,
                email
            };
            const variant = isNewOnboarding ? MP_EXP_VARIANTS.NEW : MP_EXP_VARIANTS.CONTROL;

            ldClient.identify(ldContext);
            identifySentry({ id: uuid, email, name });
            identifyMixpanel(user);
            setProfilePropsOnce({ [MP_PROPS.EXP_NEW_ONBOARDING]: variant });
            klaviyoIdentify(uuid, email);
        }
    }, [user, ldClient, isNewOnboarding]);

    useEffect(() => {
        if (!twilioInitialized.current && user) {
            twilioInitialized.current = true;
            initializeTwilioAction();
        }
    }, [initializeTwilioAction, user]);

    useEffect(() => {
        document.getElementById('app-loader')?.remove();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            {isLoaded && ldClient && <AppRoutes />}
            <Modal />
            <Toast />
        </ThemeProvider>
    );
};
