export const theme = {
    breakpoints: {
        smallMobile: 'max-width: 576px',
        mobile: 'max-width: 768px',
        tablet: 'max-width: 1024px'
    },
    fonts: {
        primary: 'Untitled Sans',
        title: 'LinotypeDidotRoman'
    },
    colors: {
        white: 'white',
        black: '#161616',
        gray400: '#98A2B3',
        gray600: '#475467',
        gray700: '#344054'
    }
};
