import React from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import content from 'content.json';
import dayjs from 'dayjs';
import { getChatUrl, getGoalstUrl } from 'services/utils/url-utils';
import { Message } from 'store/inbox-service/inbox-types';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';
import { useUserStore } from 'store/user/reducer';

import './style.scss';

const { inbox } = content;

const MessageComponent: React.FC<{ message: Message }> = ({ message }) => {
    const user = useUserStore((store) => store.user);
    const navigate = useNavigate();
    const stylistId = message?.stylist?.uuid;
    const { data: stylistData } = useGetStylistQuery(stylistId || '', { skip: !stylistId });
    const isActiveStylist = stylistData?.is_active && stylistData?.is_approved === '1';
    const isSubscriptionEnded = message.current_session_status === 'ended' && message.isSubscription;
    const showCta = !(isActiveStylist === false && message.current_session_status === 'ended');
    const canBookAgain =
        !message.current_session_status && !message.stylist.is_bot && !message.isSubscription && isActiveStylist;

    const getButtonText = () => {
        const { sender, type } = message.last_message;
        let text = inbox.button[sender === 'stylist' ? 'reply' : 'chat'];
        if (type === 'moodboard' || type === 'check_out_your_outfit' || type === 'items_collection') {
            text = inbox.button.review;
        }
        if (message.current_session_status === 'ended' && message?.stylist?.first_name) {
            text = inbox.button.again.replace('%stylist%', message.stylist.first_name);
        }
        return text;
    };

    const getMessageText = () => {
        const last_message = message.last_message;
        switch (last_message.type) {
            case 'love':
                return <Image src={inbox.icons.heart} />;
            case 'like':
                return <Image src={inbox.icons.like} />;
            case 'media_image':
                return <Image src={inbox.icons.photo} />;
            default:
                return last_message.text;
        }
    };

    const onSelect = ({ stylist, current_session_status }: { stylist: any; current_session_status: any }) => {
        if (current_session_status === 'rebook') {
            navigate(getGoalstUrl({ stylistId: stylist.uuid }));
        } else {
            navigate(getChatUrl(stylist.uuid, user!.user_uuid));
        }
    };

    const messageTimestamp = message.last_message.created
        ? dayjs(message.last_message.created).format('MMMM DD, YYYY')
        : '';

    return (
        <Row className="message">
            <Col className="stylist-info">
                <Link to={message.stylist.is_bot ? '/inbox' : `/stylist/${message.stylist.uuid}/profile`}>
                    <Image src={message.stylist.picture} />
                </Link>
                {message.plan && inbox.plans[message.plan] && (
                    <p className={`d-none d-sm-block ${message.plan === 'cleanout' ? 'cleanout' : ''}`}>
                        {inbox.plans[message.plan]}
                    </p>
                )}
            </Col>
            <Col>
                <div className="text" onClick={() => onSelect(message)}>
                    <p className="name">{message.stylist.first_name}</p>
                    <p className="content">{getMessageText()}</p>
                    <p className="date">{messageTimestamp}</p>
                </div>
                <div className="buttons">
                    {canBookAgain && (
                        <Button
                            variant="warning"
                            className="d-none d-md-block"
                            onClick={() =>
                                onSelect({
                                    ...message,
                                    current_session_status: 'rebook'
                                })
                            }
                        >
                            {message?.stylist?.first_name &&
                                inbox.button.book.replace('%stylist%', message?.stylist?.first_name)}
                        </Button>
                    )}
                    {message.current_session_status === 'ended' && (
                        <Button variant="warning" className="d-none d-md-block" onClick={() => onSelect(message)}>
                            {inbox.button.session}
                        </Button>
                    )}
                    {(!isSubscriptionEnded || !message.isSubscription) && showCta && (
                        <Button
                            variant="light"
                            onClick={() =>
                                onSelect({
                                    ...message,
                                    current_session_status:
                                        message.current_session_status === 'ended'
                                            ? 'rebook'
                                            : message.current_session_status
                                })
                            }
                        >
                            {getButtonText()}
                        </Button>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default MessageComponent;
