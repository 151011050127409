import React, { FC, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useWithDispatch } from 'hooks';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { setMixpanelStore } from 'store/common/actions';
import { useCommonStore } from 'store/common/reducer';
import { GoalType } from 'types/plans';

import { Page, Plans } from 'components';
import content from 'content.json';

import { GoalsHeader } from './GoalsHeader';

import './style.scss';
import { useIsLoggedIn } from 'store/auth-service';
import { urlParamToJson, getSessionCheckoutUrl } from 'services/utils/url-utils';
import { CheckoutParams, PlanParams } from './types';
import { Plan } from 'store/booking/types';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';

const { goals, pricing } = content;

const mappedGoals = new Map(goals.answers.map((x) => [x.value, x.name] as [GoalType, string]));

export const StylingSessionPlansPage: FC = () => {
    const [searchParams] = useSearchParams();
    const { stylistId, goal, bookingSource } = urlParamToJson<PlanParams>(searchParams.get('booking'));
    const { data: stylist } = useGetStylistQuery(stylistId || '', { skip: !stylistId });
    const goalName = mappedGoals.get(goal ?? '') ?? '';
    const isLoggedin = useIsLoggedIn();
    const navigate = useNavigate();
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);

    useEffect(() => {
        if (stylistId && isLoggedin)
            trackEvent({
                name: MP_EVENTS.PLAN_VIEWS,
                properties: {
                    ...mixpanelStore.onboarding,
                    ...bookingSourceToProps(bookingSource)
                }
            });
        klaviyoTrack(KlaviyoEvent.PLAN_VIEW);
    }, []);

    const onPlanSelect = (plan: Plan) => {
        setMixpanelStoreAction({
            onboarding: {
                ...mixpanelStore.onboarding,
                [MP_PROPS.PLAN_TYPE]: plan.value
            }
        });
        trackEvent({
            name: MP_EVENTS.PLAN_SELECTION,
            properties: {
                ...mixpanelStore.onboarding,
                [MP_PROPS.PLAN_TYPE]: plan.value,
                ...bookingSourceToProps(bookingSource)
            }
        });

        const checkoutUrl = getSessionCheckoutUrl({
            stylistId,
            goal,
            plan: plan['value'],
            bookingSource
        } as CheckoutParams);

        klaviyoTrack(KlaviyoEvent.PLAN_CLICK, {
            goal: goal,
            plan: plan.value,
            stylistId: stylistId,
            stylistName: stylist?.name,
            stylistProfileImage: stylist?.profile_picture,
            targetUrl: `${location.origin}/login?target=${encodeURIComponent(checkoutUrl)}`
        });

        navigate(checkoutUrl);
    };

    const onBack = () => {
        navigate(-1);
    };

    return (
        <Page footer={false} header={false} fixedHeader={false}>
            <Container className="goals-plans">
                <GoalsHeader onBack={onBack} stylistId={stylistId!} />
                {goal && (
                    <>
                        <Row className="chat-row">
                            <Col>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: goals.recommendation[goal as GoalType].replace('%plan%', goalName)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Plans
                                plans={goal === 'lux' ? pricing.plans.slice(1) : pricing.plans}
                                recommended={goal === 'lux' ? (pricing.plans[2].value as GoalType) : goal}
                                onSelect={onPlanSelect}
                            />
                        </Row>
                    </>
                )}
            </Container>
        </Page>
    );
};
