import { FC } from 'react';
import { TitleH2 } from 'theme';

import { HowItWorks as HowItWorksSteps } from 'components';

import { Container } from './HowItWorks-styles';

export const HowItWorks: FC = () => {
    return (
        <Container>
            <TitleH2>How It Works</TitleH2>
            <HowItWorksSteps />
        </Container>
    );
};
