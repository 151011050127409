import { me, rootSplitApi } from 'store/root-api-slice';
import { Stylist, StylistId } from 'types/user';
import { ResponseReviewItem, ReviewItem } from 'types/utils';

import {
    GetReviewsRequest,
    GetStylistsRequest,
    LabelValuePair,
    NewReviewBody,
    ReviewUser,
    ReviewUserResponse,
    StylistReview,
    StylistReviewResponse
} from './stylist-api-types';

function providesList<R extends { id: string | number }[], T extends string>(
    resultsWithIds: R | undefined,
    tagType: T
) {
    return resultsWithIds
        ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
        : [{ type: tagType, id: 'LIST' }];
}

const transformReviewUser = (user: ReviewUserResponse): ReviewUser => ({
    id: user.uuid,
    firstName: user.first_name,
    lastName: user.lastName,
    picture: user.picture,
    pictureLarge: user.picture_large,
    pictureMedium: user.picture_medium,
    pictureSmall: user.picture_small
});

const transformStylistReview = (review: StylistReviewResponse, stylistId: string): StylistReview => ({
    ...review,
    id: review.user.uuid + review.created,
    stylistId,
    title: review.title ?? undefined,
    user: transformReviewUser(review.user)
});

const transformLatestReview = (review: ResponseReviewItem): ReviewItem => ({
    ...review,
    date: new Date(review.created).toLocaleString('default', {
        month: 'short',
        year: 'numeric'
    }),
    clientName: `${review.clientFirstName} ${review.clientLastName}`,
    stylistName: `${review.stylistFirstName} ${review.stylistLastName}`,
    stylistUuid: `${review.stylistUuid}`
});

const apiWithTag = rootSplitApi.enhanceEndpoints({
    addTagTypes: ['Stylists', 'Stylist', 'Reviews', 'AllStylistNames', 'LatestReviews']
});

export const stylistApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
        getAllStylistNames: build.query<LabelValuePair[], void>({
            query: () => 'proxy/stylist/all/names',
            providesTags: ['AllStylistNames'],
            transformResponse: (response: { stylist_name: string; stylist_uuid: string }[]) =>
                response.map((stylist) => ({
                    value: stylist.stylist_uuid,
                    label: stylist.stylist_name
                }))
        }),

        getStylists: build.query<Stylist[], GetStylistsRequest>({
            query: ({ count = 15, user_gender }) => ({
                url: 'proxy/stylist/readAll',
                params: {
                    count,
                    user_gender
                }
            }),
            providesTags: ['Stylists'],
            transformResponse: (response: { items: Stylist[] }) => response.items.filter((stylist) => !stylist.picture)
        }),

        getStylist: build.query<Stylist, StylistId>({
            query: (stylistId) => ({
                url: `proxy/stylist/${stylistId}/read`,
                method: 'GET',
                params: { user_uuid: me }
            }),
            providesTags: (result) => [{ type: 'Stylist', id: result?.uuid }]
        }),

        getStylistReviews: build.query<StylistReview[], GetReviewsRequest>({
            query: ({ stylistId, from = 0, count = 5 }) => ({
                url: `proxy/stylist/${stylistId}/reviews`,
                method: 'GET',
                params: { from, count }
            }),
            providesTags: (result) => providesList(result, 'Reviews'),
            transformResponse: (response: StylistReviewResponse[], meta, arg) =>
                response.map((review) => transformStylistReview(review, arg.stylistId))
        }),

        addStylistReview: build.mutation<void, { stylistId: string; review: NewReviewBody }>({
            query: ({ stylistId, review }) => ({
                url: `proxy/stylist/${stylistId}/review`,
                method: 'POST',
                body: {
                    ...review
                }
            }),
            invalidatesTags: [{ type: 'Reviews', id: 'LIST' }]
        }),

        getLatestReviews: build.query<ReviewItem[], void>({
            query: () => ({
                url: 'review/latest',
                params: { limit: 20 }
            }),
            providesTags: ['LatestReviews'],
            transformResponse: (response: { reviews: ResponseReviewItem[] }) =>
                response.reviews.map(transformLatestReview)
        })
    })
});

export const {
    useGetAllStylistNamesQuery,
    useGetStylistQuery,
    useLazyGetStylistQuery,
    useGetStylistReviewsQuery,
    useAddStylistReviewMutation,
    useGetStylistsQuery,
    useGetLatestReviewsQuery
} = stylistApi;
