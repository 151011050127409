import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { getStylistSearchUrl } from 'services/utils/url-utils';
import { useBestMatchesQuery } from 'store/user-service/user-api-slice';
import { TitleH1 } from 'theme';

import { Loader, MinimalNavBar, Page } from 'components';

import { Container } from './Match-styles';
import { BestMatch, HowItWorks, OtherStylists } from './sections';

export const Match: FC = () => {
    const navigate = useNavigate();
    const { data: matches = [], isFetching } = useBestMatchesQuery();
    const [bestMatch, ...otherMatches] = [...matches].sort((a, b) => b.match_percentage - a.match_percentage);

    useEffect(() => {
        bestMatch &&
            trackEvent({
                name: MP_EVENTS.SINGLE_MATCH_PAGE_VIEW,
                properties: { [MP_PROPS.STYLIST_NAME]: bestMatch.name }
            });
    }, [bestMatch]);

    if (!isFetching && !bestMatch) {
        navigate(getStylistSearchUrl());
        return <></>;
    }

    return (
        <Page header={false} footer={false}>
            <MinimalNavBar />
            {isFetching ? (
                <Loader />
            ) : (
                <Container>
                    <TitleH1>Meet Your Stylist Match!</TitleH1>
                    <BestMatch stylistId={bestMatch.uuid} matchPercentage={bestMatch.match_percentage} />
                    <HowItWorks />
                    <OtherStylists bestMatch={bestMatch} otherMatches={otherMatches} />
                </Container>
            )}
        </Page>
    );
};
