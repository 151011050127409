import { FC } from 'react';

import { SectionContainer } from '../components';
import { scrollToAnchor } from '../helpers';

import {
    ButtonsContainer,
    Content,
    LeftPanel,
    NavButton,
    NavButtonSecondary,
    Paragraph,
    RightPanel,
    Title
} from './Hero-styles';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';

const howItWorksClick = () => {
    scrollToAnchor('how-it-works-section-anchor');
    trackEvent({
        name: MP_EVENTS.HOW_IT_WORKS_CLICK,
        properties: { [MP_PROPS.SOURCE]: MP_VALUES.LANDING_PAGE, [MP_PROPS.ELEMENT]: 'landing page hero' }
    });
};
export const Hero: FC = () => {
    return (
        <SectionContainer $backgroundColor="#FFFBF5">
            <Content>
                <LeftPanel>
                    <Title>#1 App for Personalized Luxury Styling </Title>
                    <Paragraph>
                        Wishi connects you with style experts to help you shop online while incorporating what you
                        already own to build your perfect wardrobe.
                    </Paragraph>
                    <ButtonsContainer>
                        <NavButton trackingElement="landing page hero" />
                        <NavButtonSecondary as="button" to="" onClick={howItWorksClick}>
                            How It Works
                        </NavButtonSecondary>
                    </ButtonsContainer>
                </LeftPanel>
                <RightPanel></RightPanel>
            </Content>
        </SectionContainer>
    );
};
