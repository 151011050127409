import './GiftCardChoose.scss';

import { Page } from 'components';
import { useIsMobile } from 'hooks';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Validator } from 'services';
import { giftPlans } from 'store/payments/consts';
import { useUser } from 'store/user/reducer';

import { Designs, Details } from './Sections';
import { jsonToUrlParam } from 'services/utils/url-utils';

export const GiftCardChoose = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const plan = searchParams.get('plan');
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const [giftCard, setGiftCard] = useState({});
    const [errors, setErrors] = useState({});
    const user = useUser();

    const onSubmit = () => {
        const { amount, additionalAmount, recipientName, recipientEmail, senderName, senderEmail, message } = giftCard;

        const checkoutParams = {
            plan,
            additionalAmount: additionalAmount || null,
            message,
            recipient: {
                name: recipientName,
                email: recipientEmail
            },
            sender: {
                name: senderName,
                email: senderEmail
            }
        };

        const errors = {
            recipientName: Validator.nameSimple(recipientName),
            senderName: Validator.nameSimple(senderName),
            recipientEmail: Validator.email(recipientEmail),
            senderEmail: Validator.email(senderEmail)
        };

        const errorsCount = Object.entries(errors).filter(([, e]) => !!e).length;

        if (!!amount && !errorsCount) {
            navigate(`/gift-cards/checkout?gift=${jsonToUrlParam(checkoutParams)}`);
        } else {
            setErrors(errors);
        }
    };

    useEffect(() => {
        (!plan || !giftPlans[plan]) &&
            setSearchParams(
                (params) => {
                    params.set('plan', 'major');
                    return params;
                },
                { replace: true }
            );
    }, [plan, setSearchParams]);

    useEffect(() => {
        if (plan) {
            const card = giftPlans[plan];

            if (card) {
                setGiftCard((giftCard) => ({ ...giftCard, amount: card.price }));
            }
        }
    }, [plan]);

    useEffect(() => {
        if (user) {
            setGiftCard((giftCard) => ({
                ...giftCard,
                senderName: `${user.first_name} ${user.last_name}`,
                senderEmail: user.email
            }));
        }
    }, [user]);

    return (
        <Page footer={false}>
            <Container className="gift-card-choose">
                {isMobile ? (
                    <>
                        <Row className="d-flex">
                            <Col>
                                <Details
                                    giftCard={giftCard}
                                    setGiftCard={setGiftCard}
                                    errors={errors}
                                    setErrors={setErrors}
                                />
                            </Col>
                        </Row>
                        <div className="continue d-flex">
                            <Button variant="dark" onClick={onSubmit}>
                                Continue
                            </Button>
                        </div>
                    </>
                ) : (
                    <Row className="d-sm-flex">
                        <Col>
                            <Designs />
                        </Col>
                        <Col>
                            <Details
                                onSubmit={onSubmit}
                                giftCard={giftCard}
                                setGiftCard={setGiftCard}
                                errors={errors}
                                setErrors={setErrors}
                            />
                        </Col>
                    </Row>
                )}
            </Container>
        </Page>
    );
};
