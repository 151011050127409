import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButtonLink } from 'theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 60px;
    padding: 60px;
    background: #fffbf5;
`;

export const StylistsContainer = styled.div`
    display: flex;
    gap: 54px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px 0;
`;
export const Stylist = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    width: 230px;
    padding: 24px 13px 8px 13px;
    border-radius: 4px;
    background: white;
    text-align: center;
    box-shadow:
        -19px 61px 25px rgba(36, 36, 36, 0.03),
        -11px 34px 21px rgba(36, 36, 36, 0.09),
        -5px 15px 16px rgba(36, 36, 36, 0.14),
        -1px 4px 9px rgba(36, 36, 36, 0.17);
`;
export const Picture = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid #e4e7ec;
`;
export const Name = styled.div`
    font-family: LinotypeDidotRoman, serif;
    font-size: 25px;
`;
export const Location = styled.div`
    color: #667085;
    margin-top: 4px;
`;
export const Match = styled.div`
    color: #667085;
`;
export const MeetButton = styled(PrimaryButton)`
    width: 100%;
    margin-top: auto;
`;

export const OtherStylistsButton = styled(SecondaryButtonLink)`
    width: 200px;
    margin-top: auto;
`;
