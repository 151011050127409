import { FC, useEffect, useRef, useState } from 'react';

import { VideoPlaceholder } from './styles';

interface VideoPlayerProps {
    videoId: string;
    isActive: boolean;
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ videoId, isActive }) => {
    const ref = useRef<HTMLIFrameElement>(null);
    const [loaded, setLoaded] = useState(false);
    const loadRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isActive && ref.current?.src) {
            ref.current.src += '';
        }
    }, [isActive]);

    useEffect(() => {
        if (!loaded) {
            const loadTrigger = loadRef.current;
            const handler = () => {
                if (loadTrigger && loadTrigger.getBoundingClientRect().top <= window.innerHeight) {
                    setLoaded(true);
                    removeHandler();
                }
            };
            const removeHandler = () => window.removeEventListener('scroll', handler);

            window.addEventListener('scroll', handler);

            return removeHandler;
        }
    }, [loaded]);

    if (!loaded) {
        return <VideoPlaceholder ref={loadRef}></VideoPlaceholder>;
    }

    return (
        <iframe
            ref={ref}
            src={`https://www.youtube-nocookie.com/embed/${videoId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
    );
};
