import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const title = css`
    font-family: ${({ theme }) => theme.fonts.title}, serif;
    font-size: 40px;
    font-weight: normal;
    letter-spacing: -1.2px;
    line-height: 140%;
    text-align: center;

    @media screen and (${({ theme }) => theme.breakpoints.mobile}) {
        font-size: 30px;
    }
`;

const primaryButton = css`
    display: block;
    padding: 14px;
    border: 1px solid #161616;
    background: #161616;
    font-size: 16px;
    color: white;
    border-radius: 4px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;

    @media (hover: hover) {
        &:hover {
            color: white;
            background-color: #344054;
        }
    }

    &:active {
        background-color: #101828;
    }
`;

const secondaryButton = css`
    ${primaryButton};

    color: #161616;
    background: transparent;
    border-color: #1d2939;

    @media (hover: hover) {
        &:hover {
            color: white;
            background-color: #1d2939;
        }
    }

    &:active {
        background-color: #344054;
    }
`;

export const TitleH1 = styled.h1`
    ${title};
`;
export const TitleH2 = styled.h2`
    ${title};
`;

export const PrimaryButton = styled.button`
    ${primaryButton};
`;

export const PrimaryButtonLink = styled(Link)`
    ${primaryButton};
`;

export const SecondaryButton = styled.button`
    ${secondaryButton};
`;

export const SecondaryButtonLink = styled(Link)`
    ${secondaryButton};
`;

export const ToggleButton = styled.button<{ $on: boolean }>`
    display: block;
    padding: 10px 14px;
    border: 1px solid #161616;
    background: ${({ $on }) => ($on ? '#161616' : 'white')};
    font-size: 18px;
    color: ${({ $on }) => ($on ? 'white' : '#161616')};
    border-radius: 4px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;

    @media (hover: hover) {
        /* &:hover {
            color: white;
            background-color: ${({ $on }) => ($on ? '#344054' : '#1d2939')};
        }*/
    }

    &:active {
        background-color: ${({ $on }) => ($on ? '#101828' : '#344054')};
    }
`;
