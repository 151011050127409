import mixpanel, { Dict } from 'mixpanel-browser';
import { Stylist } from 'types/user';
import { Client } from 'types/user';

import { MP_EVENTS, MP_PROPS } from './consts';
import { extractUserDataForMixpanelPeople } from './utils';

const detectDeviceType = (width: number) => {
    if (width > 992) return 'desktop';
    else if (width < 992 && width > 567) return 'tablet';
    else return 'mobile';
};

export const initMixpanel = () => {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
            debug: process.env.NODE_ENV !== 'production'
        });
        mixpanel.register({
            Platform: 'web',
            Device: detectDeviceType(window.innerWidth)
        });
        trackEvent({ name: MP_EVENTS.WEBSITE_VISITS });
    }
};

export const trackEvent = (eventDetails: { name: MP_EVENTS; properties?: Dict }) =>
    mixpanel.track(eventDetails.name, eventDetails.properties);

export const registerProperties = (properties: Dict) => mixpanel.register(properties);
export const setProfileProps = (properties: Dict) => mixpanel.people.set(properties);
export const setProfilePropsOnce = (properties: Dict) => mixpanel.people.set_once(properties);

export const isMatchedStylist = (stylists: Stylist[], stylistUUID: string) =>
    !!stylists.find(({ uuid }) => uuid === stylistUUID);

export const identify = (user: Client) => {
    const uuid = user.user_uuid;

    if (uuid && mixpanel.get_distinct_id() !== uuid) {
        mixpanel.register({
            [MP_PROPS.USER_TYPE]: 'user',
            [MP_PROPS.UUID]: user.user_uuid,
            [MP_PROPS.COUNTRY]: user.country,
            [MP_PROPS.FIRST_NAME]: user.first_name,
            [MP_PROPS.LAST_NAME]: user.last_name,
            [MP_PROPS.GENDER]: user.gender,
            [MP_PROPS.ORDER_COUNT]: user.counters?.purchases?.toString(),
            [MP_PROPS.BOOKING_COUNT]: user.bookings_count,
            [MP_PROPS.USER_STATUS]: user.level ?? ''
        });
        mixpanel.identify(uuid);
        setProfileProps(extractUserDataForMixpanelPeople(user));
    }
};

export const resetMixpanelIdentity = () => mixpanel.reset();
