import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    --height: 70px;

    height: var(--height);

    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        --height: 55px;
    }
`;
export const Content = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: var(--height);
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 10px;
    background: white;
    border-bottom: 1px solid #e7e7e7;
    z-index: 1000;

    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        justify-content: left;
    }
`;

export const BackButton = styled.button`
    display: none;
    background: none;
    margin: 2px 4px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    padding: 4px;
    transform: rotate(135deg);

    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        display: block;
    }
`;

export const Logo = styled(Link)`
    height: 100%;
    width: 100px;
    background: url(https://media-cf.wishi.me/react/landing/logo_black.svg) left center/contain no-repeat;

    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        width: 50px;
        background-image: url(https://media-cf.wishi.me/react/landing/wishi_black.svg);
    }
`;

export const Progression = styled.div`
    display: none;
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    line-height: 1;
    margin-left: 7px;

    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        display: block;
    }
`;
