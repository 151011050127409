import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { selectNewOnboarding, setNewOnboarding } from './features-slice';

export const useNewOnboardingFlag = (fallback = false): boolean => {
    const dispatch = useDispatch();
    const { newOnboardingFlowSwitch: masterSwitch } = useFlags();
    const flag = useSelector(selectNewOnboarding);

    if (flag === undefined) {
        dispatch(setNewOnboarding(Math.random() > 0.5));
        //dispatch(setNewOnboarding(true));
        return fallback;
    }
    return masterSwitch && flag;
};
