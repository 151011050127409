import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { rtkQueryErrorHandler } from 'store/middleware/global-error-handler';

import { rootReducer } from './reducers';
import { rootSplitApi } from './root-api-slice';
import { persistReducer, persistStore, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import featuresSlice from './features-service/features-slice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [featuresSlice.name]
};

export const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }).concat(rootSplitApi.middleware, rtkQueryErrorHandler),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(Sentry.createReduxEnhancer({}))
});

export const persistor = persistStore(store);
