import React, { FC, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import content from 'content.json';
import { useWithDispatch } from 'hooks';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { getMatchPageUrl, getSessionCheckoutUrl, urlParamToJson } from 'services/utils/url-utils';
import { Plan } from 'store/booking/types';
import { setMixpanelStore } from 'store/common/actions';
import { useCommonStore } from 'store/common/reducer';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';
import { useGetStyleDataQuery } from 'store/user-service/user-api-slice';
import { TitleH1 } from 'theme';
import { PlanType } from 'types/plans';
import { StylistId } from 'types/user';

import { Loader, MinimalNavBar, Page, Plans } from 'components';

import { Container } from './MatchPlans-styles';

const {
    pricing: { plans }
} = content;

const [, ...choicePlans] = plans;
const bookingSource = { source: 'match' };

export const MatchPlans: FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const bookingParam = searchParams.get('booking');
    const { stylistId } = urlParamToJson<{ stylistId: StylistId }>(bookingParam);
    const { data: userStyleData, isFetching } = useGetStyleDataQuery();
    const { data: stylist } = useGetStylistQuery(stylistId!, { skip: !stylistId });
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);

    const recommendedPlan = useMemo(() => {
        const { needs } = userStyleData || {};

        if (needs && (needs.length > 2 || needs.find(({ key }) => key === 'GOAL_CLOSET_CLEANOUT'))) {
            return 'lux';
        }

        return 'major';
    }, [userStyleData]);

    const onPlanSelect = ({ value }: Plan) => {
        setMixpanelStoreAction({
            onboarding: {
                ...mixpanelStore.onboarding,
                [MP_PROPS.PLAN_TYPE]: value
            }
        });
        trackEvent({
            name: MP_EVENTS.PLAN_SELECTION,
            properties: {
                ...mixpanelStore.onboarding,
                [MP_PROPS.PLAN_TYPE]: value,
                ...bookingSourceToProps(bookingSource)
            }
        });

        const checkoutUrl = getSessionCheckoutUrl({
            stylistId,
            goal: recommendedPlan,
            plan: value as PlanType,
            bookingSource
        });

        klaviyoTrack(KlaviyoEvent.PLAN_CLICK, {
            goal: recommendedPlan,
            plan: value,
            stylistId: stylistId,
            stylistName: stylist?.name,
            stylistProfileImage: stylist?.profile_picture,
            targetUrl: `${location.origin}/login?target=${encodeURIComponent(checkoutUrl)}`
        });

        navigate(checkoutUrl);
    };

    useEffect(() => {
        if (stylistId)
            trackEvent({
                name: MP_EVENTS.PLAN_VIEWS,
                properties: {
                    ...mixpanelStore.onboarding,
                    ...bookingSourceToProps(bookingSource)
                }
            });
        klaviyoTrack(KlaviyoEvent.PLAN_VIEW);
    }, [stylistId, mixpanelStore]);

    if (!stylistId) {
        navigate(getMatchPageUrl());
    }

    return (
        <Page header={false} footer={false}>
            <MinimalNavBar />
            {isFetching ? (
                <Loader />
            ) : (
                <Container>
                    <TitleH1>Choose Your Plan</TitleH1>
                    <Plans plans={choicePlans} recommended={recommendedPlan} onSelect={onPlanSelect} />
                </Container>
            )}
        </Page>
    );
};
