import React, { useMemo } from 'react';
import { Col, Image, Row } from 'react-bootstrap';

import Content from '../../../content';

const Expertise = ({ stylist: { expertise_tags = [] } }) => {
    const tags = useMemo(
        () => Array.from(new Map(expertise_tags.map((tag) => [tag.tag_uuid, tag])).values()),
        [expertise_tags]
    );

    return tags.length ? (
        <>
            <div className="title">{Content.stylist.expertise}</div>
            <Row>
                <Col className="expertise">
                    {tags.map((expertise) => (
                        <div className="expertise-item" key={expertise.tag_uuid}>
                            <Image src={expertise.tag_picture} />
                            <div>{expertise.tag_name}</div>
                        </div>
                    ))}
                </Col>
            </Row>
            <hr />
        </>
    ) : (
        ''
    );
};
export default Expertise;
