import styled from 'styled-components';

import * as Styles from '../components';

export const SectionAnchor = styled.div`
    position: absolute;
    height: 0;
    opacity: 0;
    visibility: hidden;
    top: -50px;
`;

export const NavButtonSecondary = styled(Styles.NavButtonSecondary)`
    margin-top: 40px;
    width: 290px;
`;
