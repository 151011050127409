import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import { Order, OrderQueryParameters } from 'types/orders';
import { Client } from 'types/user';

import OrderItem from './OrderItem';

import './style.scss';

interface OrdersProps {
    orders: Order[];
    user: Client;
    loadOrders: (input: OrderQueryParameters) => void;
    ordersTotal: number;
    nextPage: number | null;
}

const Orders: React.FC<OrdersProps> = ({ orders = [], user, ordersTotal, loadOrders, nextPage }) => {
    const [list, setList] = useState<Order[]>([]);

    useEffect(() => {
        if (user) loadOrders({});
    }, [user]);

    useEffect(() => {
        if (orders && orders.length) setList(orders);
    }, [orders]);

    return (
        <Container className="orders">
            <InfiniteScroll
                hasMore={list.length < ordersTotal}
                dataLength={list.length}
                next={() => (nextPage ? loadOrders({ from: nextPage }) : null)}
                style={{ overflow: 'visible' }}
                loader={undefined}
            >
                {list
                    .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                    .map((order) => (
                        <div key={order.order_number} className="order">
                            <div className="order-details">
                                <div className="order-info">
                                    <label>Order Date</label>
                                    <p>{dayjs(order.created).format('MM/DD/YYYY')}</p>
                                </div>
                                <div className="order-info">
                                    <label>Order no</label>
                                    <p>{order.order_number}</p>
                                </div>
                            </div>
                            <div className="order-items">
                                {order.items.map((item) => (
                                    <OrderItem key={item.item_uuid} item={item} />
                                ))}
                            </div>
                        </div>
                    ))}
            </InfiniteScroll>
        </Container>
    );
};

export default Orders;
