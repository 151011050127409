import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'theme';

export const Container = styled.div`
    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        position: fixed;
        display: flex;
        justify-content: center;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 22px;
        background: #f4f4f4;
    }
`;

export const NextButton = styled(PrimaryButton)`
    width: 352px;
`;

export const SkipButton = styled(SecondaryButton)`
    width: 352px;
    background: white;
`;
