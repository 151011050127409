import { useLayoutEffect, useState } from 'react';

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useLayoutEffect(() => {
        let debounceTimer: ReturnType<typeof setTimeout>;
        const updateSize = () => {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => setIsMobile(window.innerWidth < 768), 250);
        };

        window.addEventListener('resize', updateSize);

        return () => {
            window.removeEventListener('resize', updateSize);
            clearTimeout(debounceTimer);
        };
    }, []);

    return isMobile;
};
