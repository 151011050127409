import { FC, useEffect, useState } from 'react';
import { Style as StyleService } from 'services';
import { MP_EVENTS, MP_PROPS, registerProperties, trackEvent } from 'services/mixpanel';
import { ToggleButton } from 'theme';

import { Hint, ProceedButton, StepContainer, Title } from '../components';
import { BodyType, SelectedBodyTypes, StepComponentProps } from '../types';

import { BodyTypesTagsContainer } from './BodyTypes-styles';

export const BodyTypes: FC<StepComponentProps> = ({
    onboardingState: { department, bodyTypes },
    proceed,
    rollback
}) => {
    const [bodyTypesList, setBodyTypesList] = useState<BodyType[]>([]);
    const [selectedBodyTypes, setSelectedBodyTypes] = useState<SelectedBodyTypes>(new Map(bodyTypes));

    const proceedToNextQuestion = () => {
        if (selectedBodyTypes.size) {
            const bodyTypesNames = [...selectedBodyTypes.values()].map(({ name }) => name.toLowerCase()).join(',');

            registerProperties({ [MP_PROPS.BODY_TYPE]: bodyTypesNames });
            trackEvent({
                name: MP_EVENTS.ONBOARDING_BODY_TYPE_SELECTION
            });
        }

        proceed({ bodyTypes: new Map(selectedBodyTypes) });
    };

    const toggleBodyTypeSelection = (bodyType: BodyType) => {
        setSelectedBodyTypes((prevState) => {
            const newState = new Map(prevState);
            const { uuid } = bodyType;
            newState.has(uuid) ? newState.delete(uuid) : newState.set(uuid, bodyType);

            return newState;
        });
    };

    useEffect(() => {
        !department && rollback();
    }, [department, rollback]);

    useEffect(() => {
        StyleService.getBodyTypes(department).then(({ data }) => {
            setBodyTypesList(data as unknown as BodyType[]);
        });

        trackEvent({
            name: MP_EVENTS.ONBOARDING_BODY_TYPE_VIEWS
        });
    }, [department]);

    if (!department) {
        return <></>;
    }

    return (
        <StepContainer>
            <Title>
                As stylists, we’ve mastered how to fit every body type.
                <br />
                Let’s help you look and feel great!
            </Title>
            <Hint>Check any that apply:</Hint>
            <BodyTypesTagsContainer>
                {bodyTypesList.map((bodyType) => (
                    <ToggleButton
                        key={bodyType.uuid}
                        $on={selectedBodyTypes.has(bodyType.uuid)}
                        onClick={() => toggleBodyTypeSelection(bodyType)}
                    >
                        {bodyType.name}
                    </ToggleButton>
                ))}
            </BodyTypesTagsContainer>

            <ProceedButton isSkip={!selectedBodyTypes.size} onClick={proceedToNextQuestion} />
        </StepContainer>
    );
};
