import { FC } from 'react';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';

import { HowItWorks as HowItWorksSteps } from 'components';

import { SectionContainer, SectionContent, SectionTitle } from '../components';

import { NavButtonSecondary, SectionAnchor } from './HowItWorks-styles';

export const HowItWorks: FC = () => {
    const trackHowItWorksClick = () =>
        trackEvent({
            name: MP_EVENTS.HOW_IT_WORKS_CLICK,
            properties: { [MP_PROPS.SOURCE]: MP_VALUES.LANDING_PAGE, [MP_PROPS.ELEMENT]: 'landing page how it works' }
        });

    return (
        <SectionContainer>
            <SectionAnchor id="how-it-works-section-anchor" />
            <SectionContent>
                <SectionTitle>How It Works</SectionTitle>
                <HowItWorksSteps />
                <NavButtonSecondary to="/how-it-works" onClick={trackHowItWorksClick}>
                    Learn More
                </NavButtonSecondary>
            </SectionContent>
        </SectionContainer>
    );
};
