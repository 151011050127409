import { FC, useEffect, useState } from 'react';
import { Style as StyleService } from 'services';
import { ToggleButton } from 'theme';

import { Hint, ProceedButton, StepContainer, TagsContainer, Title } from '../components';
import { Need, SelectedNeeds, StepComponentProps } from '../types';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';

export const Needs: FC<StepComponentProps> = ({ onboardingState: { needs }, proceed }) => {
    const [needsList, setNeedsList] = useState<Need[]>([]);
    const [selectedNeeds, setSelectedNeeds] = useState<SelectedNeeds>(new Map(needs));

    const proceedToNextQuestion = () => {
        const needs = [...selectedNeeds.values()].map(({ text }) => text.toLowerCase()).join(',');
        trackEvent({
            name: MP_EVENTS.ONBOARDING_NEEDS_SELECTION,
            properties: { [MP_PROPS.NEED]: needs }
        });

        proceed({ needs: new Map(selectedNeeds) });
    };

    const toggleNeedSelection = (need: Need) => {
        setSelectedNeeds((prevState) => {
            const newState = new Map(prevState);
            const { key } = need;
            newState.has(key) ? newState.delete(key) : newState.set(key, need);

            return newState;
        });
    };

    useEffect(() => {
        trackEvent({ name: MP_EVENTS.ONBOARDING_NEEDS_VIEWS });
        StyleService.getNeeds().then(({ data }) => {
            setNeedsList(data);
        });
    }, []);

    return (
        <StepContainer>
            <Title>Let’s get started, what are you looking for?</Title>
            <Hint>Check any that apply:</Hint>
            <TagsContainer>
                {needsList.map((need) => (
                    <ToggleButton
                        key={need.key}
                        $on={selectedNeeds.has(need.key)}
                        onClick={() => toggleNeedSelection(need)}
                    >
                        {need.text}
                    </ToggleButton>
                ))}
            </TagsContainer>
            <ProceedButton isSkip={!selectedNeeds.size} onClick={proceedToNextQuestion} />
        </StepContainer>
    );
};
