import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../types';

interface FeaturesState {
    newOnboarding?: boolean;
}

const initialState: FeaturesState = {};

const featuresSlice = createSlice({
    name: 'featuresSlice',
    initialState,
    reducers: {
        resetNewOnboarding(state) {
            state.newOnboarding = undefined;
        },
        setNewOnboarding(state, action: PayloadAction<boolean>) {
            state.newOnboarding = action.payload;
        }
    }
});

export const { resetNewOnboarding, setNewOnboarding } = featuresSlice.actions;

export const selectNewOnboarding = ({ featuresSlice }: RootState) => featuresSlice.newOnboarding;

export default featuresSlice.reducer;
