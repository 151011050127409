import styled from 'styled-components';

export const Container = styled.div`
    max-width: 850px;
    margin: 60px auto;

    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        margin: 16px;
    }
`;
