import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Payments, Tracking } from 'services';
import { urlParamToJson } from 'services/utils/url-utils';
import { useUserId } from 'store/auth-service';
import { useGetGiftCheckoutSessionQuery } from 'store/payments/paymentsApiSlice';
import { Gift } from 'store/payments/types';

import { Loader, Page, StripeErrorMessage } from 'components';

import './GiftCardCheckout.scss';

const stripePromise = loadStripe(Payments.getStripeKey() as string);

export const GiftCardCheckout: FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const userId = useUserId();
    const gift = urlParamToJson<Gift>(searchParams.get('gift'));
    const {
        data: checkoutSession,
        isFetching,
        isError: isSessionCreationError
    } = useGetGiftCheckoutSessionQuery(
        {
            ...gift,
            clientUuid: userId,
            returnUrl: `/gift-cards/confirmation?requestId={REQUEST_ID}`
        },
        { skip: !gift, refetchOnMountOrArgChange: true }
    );

    useEffect(() => {
        gift &&
            Tracking.tag({
                event: 'eec.checkout',
                ecommerce: {
                    checkout: {
                        actionField: { step: 1 },
                        products: [
                            {
                                name: 'Gift Card',
                                category: '/category/gift-card/',
                                variant: `Gift Card - ${gift.plan} + ${gift.additionalAmount}`,
                                quantity: 1,
                                dimension3: 'Ecommerce'
                            }
                        ]
                    }
                }
            });
    }, [gift]);

    return (
        <Page footer={false} className="gift-card-checkout">
            {isFetching && <Loader />}
            <div className="inner-content">
                <div className="header">
                    <div className="back-btn" onClick={() => navigate(-1)} />
                    <img className="logo" src="https://media-cf.wishi.me/react/landing/wishi_black.svg" alt="Wishi" />
                    <h1>Wishi Gift Card Checkout</h1>
                </div>
                {isSessionCreationError && <StripeErrorMessage />}
                {!isSessionCreationError && checkoutSession?.secret && !isFetching && (
                    <div className="checkout-form">
                        <EmbeddedCheckoutProvider
                            stripe={stripePromise}
                            /* Key is needed to trigger Stripe to rerender the checkout form on session type change. Do not remove it! */
                            key={checkoutSession.secret}
                            options={{ clientSecret: checkoutSession.secret }}
                        >
                            <EmbeddedCheckout className="stripe-checkout-container" />
                        </EmbeddedCheckoutProvider>
                    </div>
                )}
            </div>
        </Page>
    );
};
