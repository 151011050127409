import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { MP_EVENTS, MP_EXP_VARIANTS, MP_PROPS, registerProperties, trackEvent } from 'services/mixpanel';
import { getOnboardingUrl, getPageType, getStylistSearchUrl } from 'services/utils/url-utils';
import { useIsLoggedIn } from 'store/auth-service';
import { useNewOnboardingFlag } from 'store/features-service/features-hooks';

interface StylingButtonProps {
    children?: ReactNode;
    className?: string;
    trackingElement: string;
}

export const StylingButton: FC<StylingButtonProps> = ({
    children = 'Let’s Get Styling',
    trackingElement,
    className
}) => {
    const isLoggedIn = useIsLoggedIn();
    const navPath = isLoggedIn ? getStylistSearchUrl() : getOnboardingUrl();
    const trackingSource = getPageType();
    const isNewOnboarding = useNewOnboardingFlag();

    const onClick = () => {
        const commonProps = {
            [MP_PROPS.SOURCE]: trackingSource,
            [MP_PROPS.ELEMENT]: trackingElement
        };

        if (isLoggedIn) {
            trackEvent({
                name: MP_EVENTS.STYLISTS_CLICK,
                properties: commonProps
            });
        } else {
            const variant = isNewOnboarding ? MP_EXP_VARIANTS.NEW : MP_EXP_VARIANTS.CONTROL;
            registerProperties({
                [MP_PROPS.EXP_NEW_ONBOARDING]: variant
            });
            trackEvent({
                name: MP_EVENTS.TAKE_ONBOARDING_QUIZ_CLICK,
                properties: commonProps
            });
        }
    };

    return (
        <Link to={navPath} state={{ fromHome: true, mpSource: trackingSource }} onClick={onClick} className={className}>
            {children}
        </Link>
    );
};
