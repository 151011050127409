import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;
    position: relative;
    width: 100%;
    max-width: 620px;

    @media screen and (${({ theme }) => theme.breakpoints.mobile}) {
        max-width: 500px;
    }

    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        max-width: none;
        padding: 0;
        margin: 0;
    }

    // Legacy "Back" button
    .back-btn {
        left: -100px;

        @media screen and (${({ theme }) => theme.breakpoints.mobile}) {
            display: none;
        }
    }

    // Legacy Bootstrap component
    .progress {
        height: 2px;
        width: calc(100% - 60px);
        margin: 45px auto;
        background-color: #4a4a4a;

        @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
            height: 7px;
            border-radius: 0;
            width: 100%;
            margin: 0;
        }

        .progress-bar {
            display: none;
            background-color: #f88379;

            @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
                display: flex;
            }
        }
    }
`;

export const Steps = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 30px);
    top: 36px;
    left: 0;
    z-index: 2;

    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        display: none;
    }
`;

export const Step = styled.div`
    position: relative;
    width: 18px;
    height: 0;
`;
export const StepContent = styled.div<{ $isComplete: boolean; $canGoBack: boolean }>`
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 14px;
    letter-spacing: 0.4px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: ${({ $canGoBack }) => ($canGoBack ? 'pointer' : 'default')};

    &:before {
        width: 18px;
        height: 18px;
        background: ${({ $isComplete }) => ($isComplete ? 'black' : 'white')};
        border: 1px solid black;
        border-radius: 50%;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        content: '';
        transition: all 0.3s;
    }

    @media (hover: hover) {
        &:hover:before {
            background: ${({ $canGoBack, $isComplete }) => ($canGoBack || $isComplete ? 'black' : 'white')};
        }
    }
`;
export const StepLabel = styled.div`
    margin-top: 15px;
`;
