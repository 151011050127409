import { History } from 'history';
import { getUserToken } from 'services/utils/user-utils';

import { removeCurrentUser, setCurrentUser } from './utils/user-utils';
import { deleteSDKAccessToken, setSDKAccessToken } from './sdk';
import { sentryException } from './SentryLogging';
import { Credentials } from './UserAuth';
import { Config, Tracking, User, UserAuth } from '.';

const login = async (credentials: Partial<Credentials>) => {
    const result = await UserAuth.login(credentials);
    const uuid = result?.data.data ? result.data.data.uuid : result?.data.user_uuid;
    const sdkAccessToken = result?.data?.accessToken;

    sdkAccessToken && setSDKAccessToken(sdkAccessToken);

    if (result?.data?.code !== 401) {
        const token = result?.data.token;
        const { data } = await User.get(uuid, token);

        setCurrentUser({
            uuid: data.user_uuid,
            token
        });
        Tracking.tag({ event: 'ws.signIn', user_id: data.user_uuid });
        Tracking.track({ id: data.user_uuid, device: Config.isMobile() ? 'mobile-web' : 'web' }, token);
        return {
            ...data,
            token,
            sdkAccessToken
        };
    }
    sentryException(new Error('authentication failed'), 'authentication failed');
};

const logout = (history?: History) => {
    removeCurrentUser();
    deleteSDKAccessToken();

    if (history) {
        history.push('/');
    }
    return;
};

const signup = async (credentials: Credentials) => {
    const result = await UserAuth.signup(credentials);
    const sdkAccessToken = result.data.accessToken;
    const uuid = result.data.data ? result.data.data.uuid : result.data.user_uuid;
    const token = result.data.token;
    const { data } = await User.get(uuid, token);

    sdkAccessToken && setSDKAccessToken(sdkAccessToken);
    setCurrentUser({
        uuid,
        token: result.data.token
    });
    Tracking.facebook('track', 'CompleteRegistration', {
        device: Config.isMobile() ? 'mobile' : 'desktop',
        signup_with: credentials.googleAuthCode ? 'google' : 'email'
    });
    Tracking.google({
        type: 'event',
        event: 'Funnel',
        data: { event_category: 'completeRegistration' }
    });
    Tracking.google({
        type: 'event',
        event: 'conversion',
        data: { send_to: 'AW-870964131/4zBACLWwnKcBEKO3p58D' }
    });
    Tracking.tag({ event: 'ws.completeRegistration', user_id: uuid });
    return {
        ...data,
        token: result?.data.token || getUserToken(),
        sdkAccessToken,
        new_user: true
    };
};
export default {
    login,
    signup,
    logout
};
