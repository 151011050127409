import axios from 'axios';
import { getUserToken } from 'services/utils/user-utils';

import { Config } from '.';

export interface Credentials {
    token?: string;
    googleAuthCode?: string;
    googleIdToken?: string;
    email?: string;
    password?: string;
}

const UserAuth = {
    login: async (credentials: Credentials) => {
        try {
            if (credentials.token) {
                return UserAuth.tokenLogin(credentials);
            } else if (credentials.googleAuthCode) {
                return UserAuth.googleLogin(credentials.googleAuthCode);
            } else if (credentials.googleIdToken) {
                return UserAuth.googleIdTokenLogin(credentials.googleIdToken);
            } else if (credentials.email) {
                return UserAuth.emailLogin(credentials);
            }
        } catch (e) {
            return Promise.reject(e);
        }
    },
    emailLogin: async (loginData: Credentials) => {
        if (loginData.email && loginData.password) {
            return axios.put(`${Config.get('api-gw')}login/email`, {
                client: `${Config.isMobile() ? 'mobile-' : ''}web`,
                email: loginData.email,
                password: loginData.password
            });
        }
    },
    tokenLogin: async ({ token }: Credentials) => await axios.put(`${Config.get('api-gw')}login/token`, { token }),
    getToken: async () =>
        await axios.get(`${Config.get('apiroot2')}user/getToken/${Config.isMobile() ? 'mobile-' : ''}web`),
    resetPassword: async (email: Credentials) =>
        await axios.post(
            `${Config.get('apiroot2')}user/resetPassword`,
            { email },
            { headers: { token: getUserToken() } }
        ),
    signup: async (credentials: Credentials) => {
        try {
            if (credentials.googleAuthCode) {
                return UserAuth.googleLogin(credentials.googleAuthCode);
            } else {
                return UserAuth.emailSignup(credentials);
            }
        } catch (e) {
            return Promise.reject(e);
        }
    },
    emailSignup: async (credentials: Credentials) =>
        await axios.post(`${Config.get('api-gw')}signup/email`, credentials),
    googleLogin: async (googleAuthCode: string) =>
        await axios.put(`${Config.get('api-gw')}login/google`, { authCode: googleAuthCode }),
    googleIdTokenLogin: async (idToken: string) =>
        await axios.put(`${Config.get('api-gw')}login/google`, { idToken: idToken })
};

export default UserAuth;
