import styled from 'styled-components';
import { TitleH1 } from 'theme';

export const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        padding: 16px 16px 50px 16px;
    }
`;

export const Title = styled(TitleH1)`
    font-size: 24px;
    text-align: center;

    span {
        text-transform: capitalize;
    }
`;

export const Hint = styled.div`
    font-size: 14px;
`;

export const TagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    max-width: 625px;
    margin: 50px 0;
`;
