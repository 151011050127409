import styled from 'styled-components';

export const Progression = styled.div`
    display: none;
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    line-height: 1;
    margin-left: 7px;

    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        display: block;
    }
`;
