import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
`;

export const StepImage = styled.div<{ $image: string }>`
    width: 226px;
    height: 466px;
    background: url(${({ $image }) => $image}) center/cover no-repeat;
`;
