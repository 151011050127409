import './style.scss';

import { CardElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { Config } from 'services';

const stripePromise = loadStripe(Config.get('stripeKey'));

const CreditCardElement = ({ onChange, onFocus, onBlur, isDisabled, className = '' }) => (
    <Elements stripe={stripePromise}>
        <CardElement
            options={{
                style: {
                    base: {
                        fontSize: '14px',
                        fontWeight: '100',
                        fontFamily: 'inherit'
                    }
                },
                hidePostalCode: true
            }}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            className={`StripeElement stripe-card-element ${isDisabled ? 'disabled' : ''} ${className}`}
        />
    </Elements>
);

export default CreditCardElement;
