import { lazy } from 'react';

export const HowItWorks = lazy(() =>
    import('./HowItWorks/HowItWorks').then(({ HowItWorks }) => ({ default: HowItWorks }))
);
export const OnboardingExperimentA = lazy(() =>
    import('./Onboarding-experiment-a').then(({ Onboarding }) => ({ default: Onboarding }))
);
export const Onboarding = lazy(() => import('./Onboarding').then(({ Onboarding }) => ({ default: Onboarding })));
export const Stylist = lazy(() => import('./Stylist').then(({ Stylist }) => ({ default: Stylist })));
export const Terms = lazy(() => import('./Terms').then(({ Terms }) => ({ default: Terms })));
export const Privacy = lazy(() => import('./Privacy').then(({ Privacy }) => ({ default: Privacy })));
export const Pricing = lazy(() => import('./Pricing/Pricing').then(({ Pricing }) => ({ default: Pricing })));
export const OurStory = lazy(() => import('./OurStory').then(({ OurStory }) => ({ default: OurStory })));
export const Lux = lazy(() => import('./Lux').then(({ Lux }) => ({ default: Lux })));
export const LoginPage = lazy(() => import('./Login').then(({ LoginPage }) => ({ default: LoginPage })));
export const GiftCardsLanding = lazy(() =>
    import('./GiftCards').then(({ GiftCardsLanding }) => ({ default: GiftCardsLanding }))
);
export const GiftCardChoose = lazy(() =>
    import('./GiftCards').then(({ GiftCardChoose }) => ({ default: GiftCardChoose }))
);
export const GiftCardCheckout = lazy(() =>
    import('./GiftCards').then(({ GiftCardCheckout }) => ({ default: GiftCardCheckout }))
);
export const GiftCardConfirmation = lazy(() =>
    import('./GiftCards').then(({ GiftCardConfirmation }) => ({ default: GiftCardConfirmation }))
);
export const Feed = lazy(() => import('./Feed').then(({ Feed }) => ({ default: Feed })));
export const Outfit = lazy(() => import('./Feed').then(({ Outfit }) => ({ default: Outfit })));
export const Item = lazy(() => import('./Item').then(({ Item }) => ({ default: Item })));
export const Checkout = lazy(() => import('./Checkout').then(({ Checkout }) => ({ default: Checkout })));
export const Confirmation = lazy(() => import('./Checkout').then(({ Confirmation }) => ({ default: Confirmation })));
export const Campaign = lazy(() => import('./Campaign/Campaign').then(({ Campaign }) => ({ default: Campaign })));

export const Match = lazy(() => import('./Match').then(({ Match }) => ({ default: Match })));
export const MatchPlans = lazy(() => import('./MatchPlans').then(({ MatchPlans }) => ({ default: MatchPlans })));
export const StylingSessionGoalsPage = lazy(() =>
    import('./Goals').then(({ StylingSessionGoalsPage }) => ({ default: StylingSessionGoalsPage }))
);
export const StylingSessionPlansPage = lazy(() =>
    import('./Goals').then(({ StylingSessionPlansPage }) => ({ default: StylingSessionPlansPage }))
);
export const StylingSessionCheckout = lazy(() =>
    import('./Goals').then(({ StylingSessionCheckout }) => ({ default: StylingSessionCheckout }))
);
export const StylingSessionConfirmation = lazy(() =>
    import('./Goals').then(({ StylingSessionConfirmation }) => ({
        default: StylingSessionConfirmation
    }))
);
export const Quiz = lazy(() => import('./Quiz').then(({ Quiz }) => ({ default: Quiz })));
export const Welcome = lazy(() => import('./Quiz').then(({ Welcome }) => ({ default: Welcome })));
export const Inbox = lazy(() => import('./Inbox').then(({ Inbox }) => ({ default: Inbox })));
export const Chat = lazy(() => import('./Chat').then(({ Chat }) => ({ default: Chat })));
export const StylistsList = lazy(() =>
    import('./StylistsList/StylistsList').then(({ StylistsList }) => ({ default: StylistsList }))
);
export const Favorites = lazy(() => import('./Favorites').then(({ Favorites }) => ({ default: Favorites })));
export const ShoppingList = lazy(() =>
    import('./ShoppingList/ShoppingList').then(({ ShoppingList }) => ({ default: ShoppingList }))
);
export const Orders = lazy(() => import('./Orders').then(({ Orders }) => ({ default: Orders })));
export const Profile = lazy(() => import('./Profile/Profile').then(({ Profile }) => ({ default: Profile })));
export const Settings = lazy(() => import('./Settings/Settings').then(({ Settings }) => ({ default: Settings })));
