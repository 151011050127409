import { CheckoutParams, GoalParams, PlanParams, StylistParams } from 'routes/Goals/types';
import { MP_VALUES } from 'services/mixpanel';
import { StylistId } from '../../types/user';

export const getOutfitUrl = (uuid: string) => `${window.origin}/feed/outfit/${uuid}`;

export const getOnboardingUrl = (relative = true) => `${relative ? '' : window.origin}/onboarding`;

export const getStylistSearchUrl = (relative = true) => `${relative ? '' : window.origin}/stylistSearch`;
export const getMatchPageUrl = (relative = true) => `${relative ? '' : window.origin}/match`;
export const getMatchPlansPageUrl = (params: { stylistId: StylistId }, relative = true) =>
    `${relative ? '' : window.origin}/match/plans?booking=${jsonToUrlParam(params)}`;

export const getChatUrl = (stylistId: string, userId: string, relative = true) =>
    `${relative ? '' : window.origin}/chat/${stylistId}/${userId}`;

export const getStylistProfileUrl = (stylistId: string, params?: StylistParams, relative = true) => {
    const path = `${relative ? '' : window.origin}/stylist/${stylistId}/profile`;
    return params ? `${path}?booking=${jsonToUrlParam(params)}` : path;
};

export const getGoalstUrl = (params: GoalParams, relative = true) =>
    `${relative ? '' : window.origin}/goals?booking=${jsonToUrlParam(params)}`;

export const getPlanstUrl = (params: PlanParams, relative = true) =>
    `${relative ? '' : window.origin}/plans?booking=${jsonToUrlParam(params)}`;

export const getSessionCheckoutUrl = (params: CheckoutParams, relative = true) =>
    `${relative ? '' : window.origin}/checkout?booking=${jsonToUrlParam(params)}`;

export const getPageType = () => {
    // order of checks is important. first item then outfit and then feed
    // otherwise need to use regex to match the correct path
    const path = window.location.pathname;
    let ret = undefined;
    if (path === '/') {
        ret = MP_VALUES.LANDING_PAGE;
    } else if (path.startsWith('/stylistSearch')) {
        ret = MP_VALUES.STYLISTS_PAGE;
    } else if (path.includes('/item/')) {
        ret = MP_VALUES.PDP_PAGE;
    } else if (path.includes('/outfit/')) {
        ret = MP_VALUES.OUTFIT_PAGE;
    } else if (path.includes('/feed')) {
        ret = MP_VALUES.FEED_PAGE;
    } else if (path.startsWith('/stylist/')) {
        ret = MP_VALUES.STYLIST_PROFILE_PAGE;
    } else if (path === '/pricing') {
        ret = MP_VALUES.PRICING_PAGE;
    } else if (path === '/how-it-works') {
        ret = MP_VALUES.HOW_IT_WORKS_PAGE;
    } else if (path === '/gift-cards') {
        ret = MP_VALUES.GIFT_CARDS_PAGE;
    }
    return ret;
};

export const jsonToUrlParam = <T>(value: T): string => encodeURIComponent(btoa(JSON.stringify(value)));

export const urlParamToJson = <T>(value: string | null): T => {
    try {
        return value ? JSON.parse(atob(decodeURIComponent(value))) : {};
    } catch (e) {}
    return {} as T;
};
