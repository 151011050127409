import { FC } from 'react';

import { flows } from './consts';
import * as Steps from './steps';
import { Department, OnboardingStep, Progress, StepComponentProps } from './types';

const stepComponents: Record<OnboardingStep, FC<StepComponentProps>> = {
    needs: Steps.Needs,
    department: Steps.Department,
    bodyTypes: Steps.BodyTypes,
    styles: Steps.Styles
};

export const getSteps = (department: Department = 'female') => flows[department] as unknown as Progress['flow'];

export const getStepComponent = (step: OnboardingStep) => stepComponents[step];

// This is a helper function to ease navigation between steps
export const getProgress = (step: OnboardingStep, department: Department = 'female'): Progress | undefined => {
    const flow = getSteps(department);
    const stepIndex = flow?.findIndex(({ name }) => name === step);

    if (stepIndex > -1) {
        return {
            flow,
            stepIndex: stepIndex > -1 ? stepIndex : 0,
            stepTitle: flow[stepIndex].text,
            totalSteps: flow.length,
            prevStep: flow[stepIndex - 1]?.name,
            nextStep: flow[stepIndex + 1]?.name
        };
    }
};
