import { FC } from 'react';

import { Container, Step, StepImage, StepNumber, StepText } from './HowItWorks-styles';

const steps = [
    {
        text: 'Get matched with A-list stylists',
        image: '//media-cf.wishi.me/react/landing-new/how-it-works/step-1.webp'
    },
    {
        text: 'Book an online session',
        image: '//media-cf.wishi.me/react/landing-new/how-it-works/step-2.webp'
    },
    {
        text: 'Recieve Personalized shoppable looks',
        image: '//media-cf.wishi.me/react/landing-new/how-it-works/step-3.webp'
    },
    {
        text: 'Buy what you love on Wishi',
        image: '//media-cf.wishi.me/react/landing-new/how-it-works/step-4.webp'
    }
];

export const HowItWorks: FC = () => {
    return (
        <Container>
            {steps.map(({ text, image }, index) => (
                <Step key={index}>
                    <StepNumber>{index + 1}</StepNumber>
                    <StepText>{text}</StepText>
                    <StepImage $image={image} />
                </Step>
            ))}
        </Container>
    );
};
