import { FC, useEffect } from 'react';
import { useWithDispatch } from 'hooks';
import { MP_EVENTS, registerProperties, trackEvent } from 'services/mixpanel';
import { updateUser } from 'store/user/actions';
import { useUser } from 'store/user/reducer';

import { StepContainer, Title } from '../components';
import { departmentAnswers } from '../consts';
import { Department as DepartmentType, StepComponentProps } from '../types';

import { DepartmentImage, DepartmentItem, DepartmentsContainer } from './Department-styles';

export const Department: FC<StepComponentProps> = ({ onboardingState: { department, needs }, proceed, rollback }) => {
    const user = useUser();
    const updateUserAction = useWithDispatch(updateUser);
    const proceedToNextQuestion = (department: DepartmentType) => {
        registerProperties({ Gender: department });
        trackEvent({ name: MP_EVENTS.ONBOARDING_GENDER_SELECTION });

        user && updateUserAction({ gender: department });
        proceed({ department });
    };

    useEffect(() => {
        !needs && rollback();
    }, [needs, rollback]);

    useEffect(() => {
        trackEvent({ name: MP_EVENTS.ONBOARDING_GENDER_VIEWS });
    }, []);

    if (!needs) {
        return <></>;
    }

    return (
        <StepContainer>
            <Title>
                Great! We have a perfect plan for your needs!
                <br />
                What’s your preferred shopping department?
            </Title>
            <DepartmentsContainer>
                {departmentAnswers.map(({ value, label, imageUrl }) => (
                    <DepartmentItem
                        key={value}
                        $selected={department === value}
                        onClick={() => proceedToNextQuestion(value)}
                    >
                        <div>{label}</div>
                        <DepartmentImage src={imageUrl} alt={label} />
                    </DepartmentItem>
                ))}
            </DepartmentsContainer>
        </StepContainer>
    );
};
