import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import content from 'content.json';
import { useWithDispatch } from 'hooks';
import { Validator } from 'services';
import { Credentials } from 'services/UserAuth';
import { resetError } from 'store/error/actions';
import { useErrorStore } from 'store/error/reducer';
import { toggleModal } from 'store/ui/actions';
import { login } from 'store/user/actions';
import { useUser } from 'store/user/reducer';

import { GoogleLogin } from 'components/GoogleLogin/GoogleLogin';
import Loader from 'components/Loader';

import './style.scss';

const { modal } = content;

export interface SigninComponentProps {
    onSignupClick: () => void;
}

export const SigninComponent: React.FC<SigninComponentProps> = ({ onSignupClick }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [remember, setRemember] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<Partial<{ email?: string; password?: string }>>({});
    const texts = modal.signin;
    const user = useUser();
    const storedErrors = useErrorStore((store) => store.errors);
    const loginAction = useWithDispatch(login);
    const toggleModalAction = useWithDispatch(toggleModal);
    const resetErrorAction = useWithDispatch(resetError);

    const validationErrors = useMemo(() => {
        const result: Record<string, string> = {};
        storedErrors.forEach(({ field, message }) => {
            result[field] = message;
        });

        return result;
    }, [storedErrors]);

    const validate = () => {
        const errors = {
            ...Validator.email(email),
            ...Validator.password(password)
        };
        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    };

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        resetErrorAction();

        if (!loading && validate()) {
            setLoading(true);
            const result = await loginAction(
                {
                    email,
                    password
                },
                'form'
            );

            !result && setLoading(false);
        }
    };

    const onGoogleLoginSuccess = (credentials: Partial<Credentials>) => {
        setLoading(true);
        loginAction(credentials, 'google');
    };

    useEffect(() => {
        if (user) {
            setLoading(false);
            resetErrorAction();
        }
    }, [user, setLoading, resetErrorAction]);

    const loginMarkerClass = 'login-modal-button';

    return (
        <div className="signup-modal">
            <div className="logo" onClick={() => navigate('/')} />
            <Modal.Header closeButton={false}>
                <div className="title">Log in</div>
            </Modal.Header>
            <GoogleLogin disabled={loading} onSuccess={onGoogleLoginSuccess} markerClass={loginMarkerClass} />

            <form onSubmit={onSubmit}>
                <div className="input-container">
                    <input
                        data-test-id="login-email"
                        name="name"
                        type="email"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(e.target.value);
                        }}
                        placeholder={texts.email}
                        required
                    />
                    <div className="validation-error">{errors.email || validationErrors.email}</div>
                </div>

                <div className="input-container">
                    <input
                        data-test-id="login-password"
                        name="password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={texts.password}
                        required
                    />
                    <div className="validation-error">{errors.password || validationErrors.password}</div>
                </div>

                <div className="input-container actions">
                    <div className="remember-me">
                        <input type="checkbox" name="remember" onChange={(e) => setRemember(e.target.checked)} />
                        <label htmlFor="remember">{texts.remember}</label>
                    </div>

                    <strong onClick={() => toggleModalAction({ type: 'ResetPassword', data: { close: true } })}>
                        {texts.forgot}
                    </strong>
                </div>

                <button
                    id="submit"
                    type="submit"
                    className={`submit-button ${loginMarkerClass}`}
                    disabled={loading}
                    data-test-id="login-button"
                >
                    {texts.signin}
                </button>
            </form>

            <div className="no-account-message">
                {texts.account}&nbsp;
                <strong onClick={onSignupClick}>{texts.join}</strong>
            </div>
            {loading && <Loader />}
        </div>
    );
};
