import { useEffect, useState } from 'react';

type OptionalAttributes = Record<string, string>;

export const useRemoteScript = (src: string, optionalAttributes?: OptionalAttributes) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const existingScript = document.querySelectorAll(`script[src="${src}"]`)[0] as HTMLScriptElement;

        if (existingScript) {
            if (existingScript.getAttribute('data-loaded')) {
                setLoaded(true);
            } else {
                existingScript.onload = () => {
                    existingScript.setAttribute('data-loaded', 'true');
                    setLoaded(true);
                };
            }
        } else {
            const script = document.createElement('script');

            script.type = 'text/javascript';
            script.async = true;
            script.src = src;
            script.onload = () => {
                script.setAttribute('data-loaded', 'true');
                setLoaded(true);
            };

            if (optionalAttributes) {
                Object.entries(optionalAttributes).map(([attr, value]) => script.setAttribute(attr, value));
            }

            document.head.appendChild(script);
        }
    }, [src, optionalAttributes]);

    return loaded;
};
