import { FC } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { OnboardingStep, Progress } from '../types';

import { Container, Step, StepContent, Steps } from './StepsProgressBar-styles';

type StepsProgressBarProps = {
    progress: Progress;
    navigateToStep: (step: OnboardingStep) => void;
};

export const StepsProgressBar: FC<StepsProgressBarProps> = ({ progress, navigateToStep }) => {
    const { flow, stepIndex, totalSteps } = progress;
    const navigate = useNavigate();

    return (
        <Container>
            {stepIndex > 0 && <div className="back-btn" onClick={() => navigate(-1)} />}
            <ProgressBar max={totalSteps} now={stepIndex + 0.1} />
            <Steps>
                {flow.map(({ name, text }, index) => (
                    <Step key={name} onClick={() => index < stepIndex && navigateToStep(name)}>
                        <StepContent $isComplete={index === stepIndex} $canGoBack={index < stepIndex}>
                            {text}
                        </StepContent>
                    </Step>
                ))}
            </Steps>
        </Container>
    );
};
