import { Department } from './types';

export const flows = {
    female: [
        { name: 'needs', text: 'Needs' },
        { name: 'department', text: 'Department' },
        { name: 'bodyTypes', text: 'Body Type' },
        { name: 'styles', text: 'Style' }
    ],
    male: [
        { name: 'needs', text: 'Needs' },
        { name: 'department', text: 'Department' },
        { name: 'styles', text: 'Style' }
    ]
};

export const departmentAnswers: { value: Department; label: string; imageUrl: string }[] = [
    {
        value: 'female',
        label: 'Women',
        imageUrl: 'https://media-cf.wishi.me/react/onboarding/womenswear.webp'
    },
    {
        value: 'male',
        label: 'Men',
        imageUrl: 'https://media-cf.wishi.me/react/onboarding/menswear.webp'
    }
];

export const styleAnswers = [
    { value: 2, text: 'LOVE IT' },
    { value: 1, text: 'SOMETIMES' },
    { value: 0, text: 'NO' }
];
