import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    gap: 40px;
    flex-wrap: wrap;
    padding: 0 40px;
    margin-top: 40px;

    @media screen and (${({ theme }) => theme.breakpoints.tablet}) {
        max-width: 836px;
    }
`;

export const Step = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 226px;
`;

export const StepNumber = styled.div`
    font-family: LinotypeDidotRoman, serif;
    font-size: 40px;
`;

export const StepText = styled.div`
    margin: 20px -40px 24px -40px;
`;

export const StepImage = styled.div<{ $image: string }>`
    width: 226px;
    height: 466px;
    background: url(${({ $image }) => $image}) center/cover no-repeat;
`;
