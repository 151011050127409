import styled from 'styled-components';

import nextButtonIcon from './assets/next.svg';
import prevButtonIcon from './assets/prev.svg';

export const Container = styled.div`
    --prevNavOpacity: 0;
    --nextNavOpacity: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const NavDotsContainer = styled.div`
    display: flex;
    gap: 6px;
    position: absolute;
    top: var(--navDotsPosition, -24px);
    z-index: 1;
`;

export const NavDot = styled.div<{ $selected: boolean }>`
    width: var(--navDotSize, 10px);
    height: var(--navDotSize, 10px);
    background: var(--navDotColor, white);
    opacity: ${({ $selected }) => ($selected ? 1 : 0.3)};
    cursor: pointer;
    border-radius: 50%;
`;

export const NavButton = styled.div`
    position: absolute;
    width: var(--navButtonWidth, 22px);
    height: var(--navButtonHeight, var(--navButtonWidth, 22px));
    background: center no-repeat;
    cursor: pointer;
    z-index: 1;

    @media screen and (${({ theme }) => theme.breakpoints.mobile}) {
        display: none;
    }
`;

export const NavPrev = styled(NavButton)`
    opacity: var(--prevNavOpacity);
    background-image: var(--navPrevButtonIcon, url(${prevButtonIcon}));
    left: calc(0px - var(--navButtonsDistance, 32px));
`;

export const NavNext = styled(NavButton)`
    opacity: var(--nextNavOpacity);
    background-image: var(--navNextButtonIcon, url(${nextButtonIcon}));
    right: calc(0px - var(--navButtonsDistance, 32px));
`;

export const ViewPort = styled.div`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0;
        height: 0;
    }
`;

export const Slide = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-shrink: 0;
    scroll-snap-align: center;
`;
