import { FC } from 'react';

import { Container, NextButton, SkipButton } from './ProceedButton-styles';

interface ProceedButtonProps {
    onClick: () => void;
    isSkip: boolean;
    isLastQuestion?: boolean;
}

export const ProceedButton: FC<ProceedButtonProps> = ({ onClick, isSkip, isLastQuestion }) => {
    const buttonText = isLastQuestion ? 'Meet my match' : isSkip ? 'Skip' : 'Next';
    const ButtonComponent = isLastQuestion || !isSkip ? NextButton : SkipButton;

    return (
        <Container>
            <ButtonComponent onClick={onClick}>{buttonText}</ButtonComponent>
        </Container>
    );
};
