import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import content from 'content.json';
import { useWithDispatch } from 'hooks';
import { Validator } from 'services';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { Credentials } from 'services/UserAuth';
import { getPageType } from 'services/utils/url-utils';
import { resetError } from 'store/error/actions';
import { useErrorStore } from 'store/error/reducer';
import { useGetMessagesQuery } from 'store/inbox-service/inbox-api-slice';
import { changeModal, toggleModal } from 'store/ui/actions';
import { useUIStore } from 'store/ui/reducer';
import { login } from 'store/user/actions';
import { useUserStore } from 'store/user/reducer';

import { GoogleLogin } from 'components/GoogleLogin/GoogleLogin';
import Loader from 'components/Loader';

import '../Signup/style.scss';

const { modal } = content;

const Signin: React.FC = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [remember, setRemember] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<Partial<{ email?: string; password?: string }>>({});
    const texts = modal.signin;
    const user = useUserStore((store) => store.user);
    const providedRedirectUrl = useUIStore((store) => store.redirectUrl);
    const storedErrors = useErrorStore((store) => store.errors);
    const loginAction = useWithDispatch(login);
    const changeModalAction = useWithDispatch(changeModal);
    const toggleModalAction = useWithDispatch(toggleModal);
    const resetErrorAction = useWithDispatch(resetError);
    const { data: messages } = useGetMessagesQuery(undefined, {
        skip: !user || !!providedRedirectUrl
    });

    const validationErrors = useMemo(() => {
        const result: Record<string, string> = {};
        storedErrors.forEach(({ field, message }) => {
            result[field] = message;
        });

        return result;
    }, [storedErrors]);

    const redirectUrl = useMemo(() => {
        if (providedRedirectUrl) {
            return providedRedirectUrl;
        } else if (pathname === '/feed') {
            return '/feed';
        } else if (messages) {
            const hasStylistSession = messages.find(({ stylist }) => !stylist.is_bot);
            return hasStylistSession ? '/inbox' : '/stylistSearch';
        }
    }, [providedRedirectUrl, messages, pathname]);

    const validate = () => {
        const errors = {
            ...Validator.email(email),
            ...Validator.password(password)
        };
        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    };

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        resetErrorAction();

        if (!loading && validate()) {
            setLoading(true);
            const result = await loginAction(
                {
                    email,
                    password
                },
                'form'
            );

            !result && setLoading(false);
        }
    };

    const onGoogleLoginSuccess = (credentials: Partial<Credentials>) => {
        setLoading(true);
        loginAction(credentials, 'google');
    };

    const onSignupClick = () => {
        const pageType = getPageType();
        trackEvent({
            name: MP_EVENTS.SIGNUP_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: pageType,
                [MP_PROPS.ELEMENT]: MP_VALUES.NO_ACCOUNT_LINK
            }
        });

        changeModalAction({
            type: 'Signup',
            data: {
                source: pageType,
                element: MP_VALUES.NO_ACCOUNT_LINK
            }
        });
    };

    useEffect(() => {
        if (user && redirectUrl) {
            setLoading(false);
            resetErrorAction();
            toggleModalAction({ type: null });
            navigate(redirectUrl);
        }
    }, [user, redirectUrl, navigate, toggleModalAction, resetErrorAction]);

    const loginMarkerClass = 'login-modal-button';

    return (
        <div className="signup-modal">
            <div className="logo" />
            <Modal.Header closeButton>
                <div className="title">Log in</div>
            </Modal.Header>
            <GoogleLogin disabled={loading} onSuccess={onGoogleLoginSuccess} markerClass={loginMarkerClass} />

            <form onSubmit={onSubmit}>
                <div className="input-container">
                    <input
                        data-test-id="login-email"
                        name="name"
                        type="email"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(e.target.value);
                        }}
                        placeholder={texts.email}
                        required
                    />
                    <div className="validation-error">{errors.email || validationErrors.email}</div>
                </div>

                <div className="input-container">
                    <input
                        data-test-id="login-password"
                        name="password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={texts.password}
                        required
                    />
                    <div className="validation-error">{errors.password || validationErrors.password}</div>
                </div>

                <div className="input-container actions">
                    <div className="remember-me">
                        <input type="checkbox" name="remember" onChange={(e) => setRemember(e.target.checked)} />
                        <label htmlFor="remember">{texts.remember}</label>
                    </div>

                    <strong onClick={() => changeModalAction({ type: 'ResetPassword', data: {} })}>
                        {texts.forgot}
                    </strong>
                </div>

                <button
                    id="submit"
                    type="submit"
                    className={`submit-button ${loginMarkerClass}`}
                    disabled={loading}
                    data-test-id="login-button"
                >
                    {texts.signin}
                </button>
            </form>

            <div className="no-account-message">
                {texts.account}&nbsp;
                <strong onClick={onSignupClick}>{texts.join}</strong>
            </div>
            {loading && <Loader />}
        </div>
    );
};

export default Signin;
