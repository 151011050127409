import styled from 'styled-components';

export const StylesContainer = styled.div`
    position: relative;
    margin-bottom: 50px;

    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        margin-bottom: 0;
    }
`;

export const StyleItem = styled.div<{ $selected: boolean }>`
    position: ${({ $selected }) => ($selected ? 'static' : 'absolute')};
    opacity: ${({ $selected }) => ($selected ? 1 : 0)};
    pointer-events: ${({ $selected }) => ($selected ? 'auto' : 'none')};
    left: 0;
    top: 0;
    transition: opacity 1s;
`;

export const StyleImage = styled.img`
    display: block;
    height: 400px;
    width: 400px;
    margin-top: 24px;

    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        width: 100%;
        height: auto;
    }
`;
export const AnswersContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px 30px 0 30px;

    @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
        margin: 10px 15% 0 15%;
    }
`;

export const Answer = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    height: 50px;
`;

export const AnswerContent = styled.button<{ $selected: boolean }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    text-transform: uppercase;
    white-space: nowrap;
    background: none;
    border: none;
    color: black;

    &:before {
        width: 25px;
        height: 25px;
        background: ${({ $selected }) => ($selected ? 'black' : 'white')};
        border: 1px solid black;
        border-radius: 50%;
        transition: all 0.3s;
        content: '';

        @media screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
            width: 20px;
            height: 20px;
        }
    }

    @media (hover: hover) {
        &:hover:before {
            background: black;
        }
    }
`;
