import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { PrimaryButton, TitleH1 } from 'theme';

export const Container = styled.div`
    width: 100%;
    margin-top: 5px;
`;

export const ModalHeader = styled(Modal.Header)`
    && {
        align-items: start;
    }
`;

export const Profile = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    text-align: left;
`;

export const Name = styled(TitleH1)`
    font-size: 30px;
`;

export const Location = styled.div`
    color: ${({ theme }) => theme.colors.gray700};
    margin-top: 5px;
    text-transform: uppercase;
`;

export const ProfilePicture = styled.img`
    border-radius: 50%;
    width: 95px;
    height: 95px;
`;

export const ModalBody = styled.div`
    margin-top: 32px;
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
`;

export const Textarea = styled.textarea`
    border: 1px solid ${({ theme }) => theme.colors.gray400};
    border-radius: 4px;
    width: 100%;
    height: 204px;
    padding: 12px;
    margin: 16px 0;
    font-family: inherit;
    font-size: 14px;
`;

export const Note = styled.div`
    color: ${({ theme }) => theme.colors.gray600};
    font-size: 14px;
`;

export const ContactButton = styled(PrimaryButton)`
    width: 100%;
    max-width: 278px;
    margin: 32px auto 0 auto;
`;

export const SuccessMessage = styled.div`
    max-width: 100%;
    max-height: 100%;
    width: 500px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
        width: 28px;
        height: 28px;
        margin-bottom: 17px;
    }
`;
