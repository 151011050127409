import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { mobileBreakpoint } from '../consts';

export const SectionContainer = styled.div<{ $backgroundColor?: string }>`
    position: relative;
    background-color: ${({ $backgroundColor }) => $backgroundColor || 'white'};
    color: #161616;
`;

export const SectionContent = styled.div<{ $maxWidth?: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: ${({ $maxWidth }) => $maxWidth || '1172px'};
    margin: 0 auto;
    padding: 80px ${({ $maxWidth }) => ($maxWidth === 'none' ? '0' : '16px')};

    @media screen and (max-width: ${mobileBreakpoint}px) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
`;

export const Title = styled.h2`
    font-family: LinotypeDidotRoman, serif;
    font-size: 40px;
    font-weight: normal;
    letter-spacing: -1.2px;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        font-size: 30px;
    }
`;

export const SectionTitle = styled(Title)`
    text-align: center;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        font-size: 24px;
    }
`;

export const Paragraph = styled.p`
    font-family: UntitledSans, sans-serif;
    font-size: 18px;
    line-height: 140%;

    @media screen and (max-width: ${mobileBreakpoint}px) {
        font-size: 16px;
    }
`;

export const VideoPlaceholder = styled.div`
    height: 100%;
`;

export const navButton = css`
    display: block;
    padding: 14px;
    border: 1px solid #161616;
    background: #161616;
    font-size: 16px;
    color: white;
    border-radius: 4px;
    text-align: center;
    transition: all 0.3s;

    &:hover {
        color: white;
        background-color: #344054;
    }

    &:active {
        background-color: #101828;
    }
`;

export const navButtonSecondary = css`
    ${navButton};

    color: #161616;
    background: transparent;
    border-color: #1d2939;

    &:hover {
        color: white;
        background-color: #1d2939;
    }

    &:active {
        background-color: #344054;
    }
`;

export const NavButtonSecondary = styled(Link)`
    ${navButtonSecondary}
`;
