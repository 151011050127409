import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { BackButton, Container, Content, Logo } from './MinimalNavBar-styles';

type MinimalNavBarProps = {
    children?: ReactNode;
    showBack?: boolean;
};

export const MinimalNavBar: FC<MinimalNavBarProps> = ({ showBack = true, children }) => {
    const navigate = useNavigate();

    return (
        <Container>
            <Content>
                {showBack && <BackButton onClick={() => navigate(-1)} />}
                <Logo to="/" />
                {children}
            </Content>
        </Container>
    );
};
