import { FC } from 'react';

import { MinimalNavBar } from 'components';

import { Progress } from '../types';

import { Progression } from './Header-styles';

type HeaderProps = {
    progress: Progress;
};

export const Header: FC<HeaderProps> = ({ progress: { stepIndex, totalSteps, stepTitle } }) => {
    return (
        <MinimalNavBar showBack={stepIndex > 0}>
            <Progression>
                Step {stepIndex + 1} out of {totalSteps}:&nbsp;
                <strong>{stepTitle}</strong>
            </Progression>
        </MinimalNavBar>
    );
};
